const MINIO_ENDPOINT = process.env.VUE_APP_MINIO_ENDPOINT || `storage.fortify.pro`;

export const USER_DEFAULT_AVATAR = `https://blackpantherpowerfitness.com/wp-content/uploads/2020/08/blank-profile-picture-mystery-man-avatar-973460.jpg`;
export const USER_DEFAULT_NAME = `Incomplete`;
export const USER_DEFAULT_USERNAME = `Username`;
export const USER_DEFAULT_PHONE = `123-456-7890`;
export const URL_POSTS = `https://${MINIO_ENDPOINT}/posts`;
export const URL_AVATARS = `https://${MINIO_ENDPOINT}/avatars`;
export const URL_IMAGE_NOT_FOUND = `https://www.lightformshop.com/item-image-not-available.jpg`;
export const AVATAR_NOT_FOUND = `https://yur-info.ru/media/k2/items/cache/97d2860871f3d35e35f6eb0477d3015a_XL.jpg`;
export const IMAGE_TYPE = `.jpeg`;
