<template>
  <div>
    <AdminGeneralTopBar />
    <div class="admin__page container mt-5">
      <section class="reports-admin text-white mb-5 position-relative">
        <!-- TABS -->
        <div class="toggle-posts-btns text-center">
          <div
            class="btn-group d-flex flex-wrap"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            <!-- USERS -->
            <input
              id="btnradio1"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              :checked="reportedUsers"
            >
            <label
              class="btn btn-outline-info post-btn"
              for="btnradio1"
              @click="showReportedUsers"
            >Reported users</label>
            <!-- POSTS -->
            <input
              id="btnradio2"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              :checked="reportedPosts"
            >
            <label
              class="btn btn-outline-info post-btn"
              for="btnradio2"
              @click="showReportedPosts"
            >Reported posts</label>
            <!-- MESSAGES -->
            <input
              id="btnradio3"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              :checked="reportedMessages"
            >
            <label
              class="btn btn-outline-info post-btn"
              for="btnradio3"
              @click="showReportedMessages"
            >Reported messages</label>
            <!-- COMMENTS -->
            <input
              id="btnradio4"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              :checked="reportedComments"
            >
            <label
              class="btn btn-outline-info post-btn"
              for="btnradio4"
              @click="showReportedComments"
            >Reported comments</label>
          </div>
        </div>
        <!-- TABS END -->
        <!-- Reported users -->
        <section
          v-if="reportedUsers"
          class="reported-users border-top mt-3 pt-3"
        >
          <div class="reported-user-items-wrap">
            <div
              v-for="user in reportsDataList"
              :key="user.id"
              class="reported-user-item border mt-3 p-3 row position-relative"
            >
              <span class="user-post-item-text fw-lighter fst-italic col">{{
                getDate(user.updated)
              }}</span>
              <div class="d-flex flex-column col">
                <span
                  class="text-danger fw-bold user-post-item-text"
                >REPORTED</span>
                <span>Reason: {{ user.reason }}</span>
              </div>
              <div class="col">
                <img
                  :src="getAvatarUrl(user.targetId)"
                  class="user-post-avatar"
                  alt="avatar"
                  width="115"
                  height="115"
                >
              </div>
              <div class="col">
                <div class="d-grid gap-2">
                  <button
                    type="button"
                    class="btn btn-info col user-post-item-text mt-3"
                    @click="getProfile(user)"
                  >
                    Info
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- *********Reported users end ************** -->

        <!-- Reported posts -->
        <section
          v-if="reportedPosts"
          class="reported-posts border-top mt-3 pt-3"
        >
          <div class="reported-posts-item-wrap">
            <div
              v-for="(post, index) in reportsDataList"
              :key="index"
              class="reported-post-item border mt-3 p-3 row position-relative"
            >
              <span class="user-post-item-text fw-lighter fst-italic col">{{
                getDate(post.updated)
              }}</span>
              <div class="d-flex flex-column col">
                <span class="text-danger fw-bold user-post-item-text">
                  REPORTED</span>
                <span>Reason: {{ post.reason }}</span>
              </div>
              <!-- <div class="col">
                <img
                  :src="getReportedPostUrl(post)"
                  alt="image"
                  width="115"
                  height="115"
                >
              </div> -->
              <div class="col-3">
                <div class="d-grid gap-2">
                  <button
                    type="button"
                    class="btn btn-info user-post-item-text"
                    @click="goToPostPage(post, index)"
                  >
                    Info
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Reported posts end -->

        <!-- REPORTED MESSAGES -->
        <section
          v-if="reportedMessages"
          class="reported-messages border-top mt-3 pt-3"
        >
          <div
            v-for="message in reportsDataList"
            :key="message.id"
            class="reported-message-item border mt-3 ms-0 me-0 p-3 row"
          >
            <div class="col fst-italic">
              {{ getDate(message.updated) }}
            </div>
            <div class="col">
              <span class="text-danger fw-bold">REPORTED</span><br>
              <span class="fst-italic">Reason: {{ message.reason }}</span>
            </div>
            <div class="col-3">
              <button
                type="button"
                class="btn btn-info w-100"
                @click="getMessageInfo(message.targetId)"
              >
                Info
              </button>
            </div>
          </div>
        </section>
        <!-- REPORTED MESSAGES END -->
        <!-- REPORTED COMMENTS -->
        <section
          v-if="reportedComments"
          class="reported-comments border-top mt-3 pt-3"
        >
          <div
            v-for="comment in reportsDataList"
            :key="comment.id"
            class="reported-comment-item border mt-3 ms-0 me-0 p-3 row"
          >
            <div class="col">
              <span class="fst-italic">{{ getDate(comment.updated) }}</span>
            </div>
            <div class="col">
              <span class="text-danger fw-bold">REPORTED</span><br>
              <span>Reason: {{ comment.reason }}</span>
            </div>
            <div class="col-3">
              <button
                type="button"
                class="btn btn-info w-100"
                @click="getCommentInfo(comment.targetId)"
              >
                Info
              </button>
            </div>
          </div>
        </section>
        <!-- REPORTED COMMENTS END -->
        <section
          v-if="isModal"
          class="
            modal-info
            fixed-top
            top-0
            start-50
            translate-middle-x
            bg-secondary
            p-3
            shadow-lg
            rounded
          "
        >
          <button
            type="button"
            class="btn-close float-end"
            aria-label="Close"
            @click="closeModal"
          />
          <div
            v-if="dataToShow == `message`"
            class="text-center"
          >
            <p class="mt-5 ms-3 fs-4 fw-bold">
              Message
            </p>
            <p
              v-for="item in messageData[0].data"
              :key="item.id"
            >
              {{ item.content }}
            </p>
            <p>Status {{ messageData[0].status }}</p>
            <p class="fst-italic">
              Updated {{ getDate(messageData[0].updated) }}
            </p>
          </div>
          <div
            v-else-if="dataToShow == `comment`"
            class="text-center"
          >
            <p class="mt-5 ms-1 fs-4 fw-bold">
              Comment
            </p>
            <p>{{ commentData[0].text }}</p>
            <p>Status {{ commentData[0].status }}</p>
            <p>{{ getDate(commentData[0].updated) }}</p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import AdminGeneralTopBar from "../General/TopBar";
export default {
  name: "AdminReports",
  components: {
    AdminGeneralTopBar,
  },
  data() {
    return {
      reportedUsers: true,
      reportedPosts: false,
      reportedMessages: false,
      reportedComments: false,
      isTextExpanded: false,
      isModal: false,
      dataToShow: ``,
      messageData: {},
      commentData: {},
    };
  },
  computed: {
    reportsDataList() {
      return this.$store.state.reports_array.data;
    },
  },

  mounted() {
    this.reportedUsers =
      JSON.parse(sessionStorage.getItem("reportedUsers")) ?? true;
    this.reportedPosts = JSON.parse(sessionStorage.getItem("reportedPosts"));
    this.reportedMessages = JSON.parse(
      sessionStorage.getItem("reportedMessages")
    );
    this.reportedComments = JSON.parse(
      sessionStorage.getItem("reportedComments")
    );

    if (this.reportedUsers) {
      this.fetchReportsData(`ACCOUNT`);
    } else if (this.reportedPosts) {
      this.fetchReportsData(`POST`);
    } else if (this.reportedComments) {
      this.fetchReportsData(`COMMENT`);
    } else if (this.reportedMessages) {
      this.fetchReportsData(`ROOM`);
    }
  },
  methods: {
    // FETCH REPORTS DATA
    fetchReportsData(arg) {
      this.$store.dispatch(`GET_REPORTS_ARRAY`, `?type=${arg}`);
    },
    fetchModalDataMessages(arg) {
      this.$store.dispatch(`GET_MESSAGES`, `?roomId=${arg}`).then(() => {
        setTimeout(() => {
          this.messageData = this.$store.state.messages.data;
        }, 1000);
      });
    },
    fetchModalDataComments(arg) {
      this.$store.dispatch(`GET_COMMENTS`, `?id=${arg}`).then(() => {
        setTimeout(() => {
          this.commentData = this.$store.state.comments.data;
        }, 1000);
      });
    },
    saveTabValue(arg1, arg2, arg3, arg4) {
      sessionStorage.setItem("reportedUsers", arg1);
      sessionStorage.setItem("reportedPosts", arg2);
      sessionStorage.setItem("reportedMessages", arg3);
      sessionStorage.setItem("reportedComments", arg4);
      this.reportedUsers = JSON.parse(sessionStorage.getItem("reportedUsers"));
      this.reportedPosts = JSON.parse(sessionStorage.getItem("reportedPosts"));
      this.reportedMessages = JSON.parse(
        sessionStorage.getItem("reportedMessages")
      );
      this.reportedComments = JSON.parse(
        sessionStorage.getItem("reportedComments")
      );
    },
    // NAVIGATE BETWEEN MAIN TABS

    showReportedUsers() {
      this.fetchReportsData(`ACCOUNT`);
      this.saveTabValue(true, false, false, false);
    },
    showReportedPosts() {
      this.fetchReportsData(`POST`);
      this.saveTabValue(false, true, false, false);
    },
    showReportedMessages() {
      this.fetchReportsData(`ROOM`);
      this.saveTabValue(false, false, true, false);
    },
    showReportedComments() {
      this.fetchReportsData(`COMMENT`);
      this.saveTabValue(false, false, false, true);
    },

    // THIS WILL VERIFY POST

    approvePost(post, i) {
      this.$store.dispatch(`BLOCK_POST`, {
        id: post.id,
        status: {
          status: `VERIFIED`,
          statusMessage: `This post has been approved by Administrator`,
        },
      });
    },

    // GO TO POST PAGE

    goToPostPage(post, i) {
      this.$router.push(`/post/${post.targetId}`);
    },
    // GET USER PROFILE

    getProfile(user, i) {
      this.$router.push(`/profile/${user.targetId}`);
    },
    // EXPAND TEXT

    expandText(post, i) {
      if (!this.isTextExpanded) {
        this.isTextExpanded = post.id;
      } else {
        this.isTextExpanded = false;
      }
    },
    getMessageInfo(id) {
      this.isModal = true;
      this.dataToShow = `message`;
      this.fetchModalDataMessages(id);
    },
    getCommentInfo(id) {
      this.isModal = true;
      this.dataToShow = `comment`;
      this.fetchModalDataComments(id);
    },
    closeModal() {
      this.isModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

.reported-user-item {
  background-color: $item-color;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
img {
  display: block;
  object-fit: cover;
}
.reported-post-item {
  width: 100%;
  background-color: $item-color;
  margin-left: 0 !important;
}
.reported-message-item {
  width: 100%;
  background-color: $item-color;
}
.reported-comment-item {
  width: 100%;
  background-color: $item-color;
}
.user-post-item-text {
  display: block;
}
.user-post-avatar {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    width: 7em;
    height: 7em;
  }
}
.reason-input {
  height: 66%;
}
.reason-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 16em;
  height: 16em;
  margin-top: -4em;
  margin-left: -8em;
  z-index: 1;
  @include media-breakpoint-up(md) {
    width: 20em;
    height: 20em;
    margin-top: -7em;
    margin-left: -10em;
  }
}
.pagination-wrap {
  width: 100%;
  min-height: 4em;
}
.modal-info {
  width: 100%;
  height: 300px;
  margin-top: 6em;
  z-index: 2;
  @include media-breakpoint-up(md) {
    width: 500px;
  }
}
</style>
