<template>
  <div
    :id="`carouselExampleControls${largeImageData.id}`"
    class="carousel slide h-100"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div
        v-for="(item, index) in largeImageData.media"
        :key="index"
        class="carousel-item"
        :class="{ active: index == 0 }"
      >
        <img
          v-if="item.type == 'image'"
          :src="getPostImageUrl(largeImageData.id, item.id)"
          width="300"
          class="d-block w-100"
          alt="image"
        >
        <video
          v-else-if="item.type == 'video'"
          width="320"
          height="240"
          controls
          class="d-block w-100"
        >
          <source
            :src="getPostVideoUrl(largeImageData.id, item.id)"
            type="video/mp4"
          >
          <source
            :src="getPostVideoUrl(largeImageData.id, item.id)"
            type="video/ogg"
          >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      :data-bs-target="`#carouselExampleControls${largeImageData.id}`"
      data-bs-slide="prev"
    >
      <span
        class="carousel-control-prev-icon"
        aria-hidden="true"
      />
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      :data-bs-target="`#carouselExampleControls${largeImageData.id}`"
      data-bs-slide="next"
    >
      <span
        class="carousel-control-next-icon"
        aria-hidden="true"
      />
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    myProps: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    largeImageData() {
      return this.myProps.largePostMedia;
    },
  },
  mounted() {
    console.log(this.myProps.largePostMedia);
  },
};
</script>

<style lang="scss" scoped>
.carousel-inner {
  height: 93% !important;
}
</style>