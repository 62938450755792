<template>
  <section class="d-flex flex-column justify-content-center align-items-center not-found">
    <h1 class="h1 text-white">
      This Page Isn't Available
    </h1>
    <p>
      <button
        class="btn btn-secondary"
        @click="$router.go()"
      >
        Try again
      </button>
    </p>
  </section>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss" scoped>
.not-found {
  min-height: 43em;
  height: calc(100vh - 52px);
}
</style>