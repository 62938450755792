
<template>
  <section
    class="reset-page text-center container-fluid p-0 d-flex flex-column justify-content-between align-items-center pt-5"
  >
    <form
      autocomplete="off"
      class="form px-5 py-5 text-white position relative"
      @submit.prevent="setNewPassword"
    >
      <h2 class="text-white">
        Set new password
      </h2>
      <label for="token">Please, type the code:</label>
      <input
        id="token"
        v-model="token"
        name="token"
        placeholder="Code"
        class="form-control"
        type="text"
        autocomplete="new-password"
        required
        @change="isError = false"
      >
      <label for="password">Please, type new password:</label>
      <input
        id="password"
        v-model="password"
        name="password"
        placeholder="New password"
        class="form-control"
        type="password"
        autocomplete="new-password"
        required
        @change="isError = false"
      >
      <span
        v-if="isError"
        class="alert alert-warning position-absolute"
      >
        {{ errorMessage }}
      </span>
      <button
        class="btn btn-secondary my-3"
        value="Reset Password"
        type="submit"
      >
        <span>Reset password</span>
      </button>
      <router-link
        class="btn btn-link btn-sm text-white"
        to="/"
      >
        Back to Log In
      </router-link>
    </form>
    <section
      v-if="isModalShow"
      class="reset-modal d-flex flex-column justify-content-center align-items-center position-absolute top-0 start-0 w-100 bg-dark p-5"
    >
      <p class="fs-4 text-white">
        Success
      </p>
      <p class="fs-4 text-white">
        Your password has been set and your account is now ready to use!
      </p>
      <router-link
        to="/"
        class="btn btn-secondary mt-5"
      >
        Go Back
      </router-link>
    </section>
    <Footer />
  </section>
</template>

<script>
import Footer from "../components/Partials/Footer";

export default {
  name: "SetNewPassword",
  components: {
    Footer,
  },

  data() {
    return {
      existingUsers: [],
      isError: false,
      isModalShow: false,
      errorMessage: ``,
      token: ``,
      password: ``,
      email: ``
    };
  },
  methods: {
    setNewPassword() {
      const objToSend = {
        action: `resetPwdShort`,
        value: {
          user: {
            email: localStorage.getItem(`email`)
          },
          token: this.token,
          password: this.password,
        },
      };
      this.$store
        .dispatch("RESET_PASSWORD", objToSend)
        .then(() => {
          this.isError = false;
          this.isModalShow = true;
          localStorage.removeItem(`email`);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.code == `500`) {
            this.errorMessage = `Your code is not correct, please try again`;
          } else {
          this.errorMessage = `Something went wrong, please try again later.`;
          }
          this.isError = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 400px;
  @include media-breakpoint-up(md) {
    width: 400px;
    min-height: 500px;
  }
}
.hidden {
  display: none !important;
}
.reset-page,
.reset-modal {
  min-height: 100vh;
  background-image: url("../assets/img/welcome-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.alert {
  padding: 0px 5px 0px 5px !important;
}
label {
  align-self: flex-start;
}
.alert {
  width: 70%;
  transform: translate(0%, 415%);
  @include media-breakpoint-up(md) {
    width: 40%;
    transform: translate(0%, 590%);
  }
  @include media-breakpoint-up(lg) {
    width: 30%;
  }
  @include media-breakpoint-up(xl) {
    width: 25%;
  }
  @include media-breakpoint-up(xxl) {
    width: 21%;
  }
}
</style>
