<template>
  <div>
    <AdminGeneralTopBar />
    <div class="admin__page container mt-5">
      <section class="payments-approval position-relative">
        <div class="btn-group history-btn">
          <button
            type="button"
            class="btn btn-secondary dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            aria-expanded="false"
          >
            {{ dropdownTitle }}
          </button>
          <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-lg-start">
            <li>
              <button
                class="dropdown-item"
                type="button"
                @click="showPendings"
              >
                Pending requests
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                type="button"
                @click="showHistory"
              >
                Payments history
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                type="button"
                @click="showRejected"
              >
                Rejected requests
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                type="button"
                @click="showFailed"
              >
                Failed requests
              </button>
            </li>
          </ul>
        </div>

        <div
          v-for="(payment, index) in paymentsList"
          :key="index"
        >
          <section class="payment-request border-bottom container">
            <div class="row">
              <div class="col-sm">
                <img
                  class="payment-request__avatar"
                  :src="getAvatarUrl(payment.accountId)"
                  alt="avatar"
                >
              </div>
              <div class="col-sm">
                <span class="d-block payment-request__username text-white">
                  <span v-if="usersInfoArray.has(payment.accountId)">
                    {{ usersInfoArray.get(payment.accountId).username }}
                  </span>
                  <span
                    v-else
                    class="text-danger"
                  >This user does NOT exist</span>
                </span>
                <span class="d-block payment-request__name text-white">
                  <span v-if="usersInfoArray.has(payment.accountId)">
                    {{ usersInfoArray.get(payment.accountId).name }}
                  </span>
                </span>

                <div class="payment-reports text-white">
                  {{ reportsNum }} reports
                </div>
                <div class="payment-date text-white">
                  {{ getDate(payment.created) }}
                </div>
              </div>
              <div class="col-sm">
                <span
                  class="text-white"
                >Requested amount:
                  <span
                    class="text-info"
                  >${{ roundAmount(payment.amountExchanged.amount) }} <span class="fw-bold">{{ payment.amountExchanged.currency }}</span></span>
                </span>
                <div class="request-status text-danger">
                  {{ payment.status }}
                </div>

                <div class="d-grid gap-2 col-6 mx-auto">
                  <button
                    v-if="payment.status == 'PENDING'"
                    class="btn btn-info"
                    :disabled="!usersInfoArray.has(payment.accountId)"
                    @click="getApprove(payment, index)"
                  >
                    <span
                      v-if="payment.status == `PENDING`"
                      class="title-approve"
                    >Approve</span>
                  </button>
                  <button
                    v-if="payment.status == `PENDING`"
                    class="btn btn-secondary"
                    :disabled="!usersInfoArray.has(payment.accountId)"
                    @click="getDisapprovePopup(payment, index)"
                  >
                    Disapprove
                  </button>
                </div>

                <span
                  v-if="payment.status !== `PENDING` && payment.status !== `FAILED`"
                  class="text-white"
                >Reason: {{ payment.statusMessage }}
                  <div class="payment-date">
                    {{ getDate(payment.updated) }}
                  </div></span>
                <span
                  v-if="payment.status == `FAILED`"
                  class="text-white"
                >
                  ERROR: Paypal failed
                </span>
              </div>
            </div>
          </section>
        </div>
        <div
          v-if="error"
          class="error-modal"
        >
          <NotFound />
        </div>
        <div
          v-if="isApproved"
          class="
            approve-popup
            position-fixed
            top-50
            start-50
            translate-middle
            d-flex
            flex-column
            justify-content-center
            align-items-center
            bg-dark
          "
        >
          <h5 class="h5 text-white text-center">
            This payment request has been approved
          </h5>
          <button
            class="btn btn-info mt-3"
            @click="closeApprovePopup"
          >
            Ok
          </button>
        </div>
        <div
          v-if="approveError"
          class="
            approve-popup
            position-fixed
            top-50
            start-50
            translate-middle
            d-flex
            flex-column
            justify-content-center
            align-items-center
            bg-dark
          "
        >
          <h5 class="h5 text-white text-center">
            This payment request cannot be approved!
          </h5>
          <button
            class="btn btn-info mt-3"
            @click="closeApprovePopup"
          >
            Ok
          </button>
        </div>
        <form
          v-if="disapprovalPopup"
          class="
            reject-reason
            position-fixed
            top-50
            start-50
            translate-middle
            d-flex
            flex-column
            justify-content-between
            align-items-center
            bg-dark
          "
          @submit.prevent="disapproveRequest"
        >
          <h4 class="text-white">
            Disapproval reason:
          </h4>
          <textarea
            id="reject-input"
            v-model="reason"
            class="w-100 bg-dark text-white p-1"
            name="reject-input"
            rows="4"
            cols="20"
            placeholder="Payment request disapproval..."
            required
          />
          <div class="wrap w-100 d-flex justify-content-between align-items-center">
            <button
              type="submit"
              class="btn btn-warning reject-reason-btn"
              :disabled="disapproveBtnDisable"
            >
              Disapprove
            </button>
            <button
              type="button"
              class="btn btn-secondary reject-reason-btn"
              @click="closeDisapprovePopup"
            >
              Cancel
            </button>
          </div>
        </form>
        <div class="pagination-wrap mt-5">
          <PaginationNavigation />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import PaginationNavigation from "../../Partials/PaginationNavigation.vue";
import NotFound from "../../../views/NotFound";
import AdminGeneralTopBar from "../General/TopBar";
import {
  USER_DEFAULT_AVATAR,
  USER_DEFAULT_NAME,
  USER_DEFAULT_USERNAME,
} from "../../../constants.js";

export default {
  name: "AdminPayments",
  components: {
    NotFound,
    PaginationNavigation,
    AdminGeneralTopBar,
  },
  data() {
    return {
      userAvatar: USER_DEFAULT_AVATAR,
      userName: USER_DEFAULT_NAME,
      userUsername: USER_DEFAULT_USERNAME,
      error: false,
      isApproved: false,
      approveError: false,
      hideRequest: false,
      disapprovalPopup: false,
      requestId: ``,
      reason: ``,
      sortStatusPending: `?status=PENDING`,
      sortStatusCompleted: `?status=COMPLETED`,
      sortStatusRejected: `?status=REJECTED`,
      sortStatusFailed: `?status=FAILED`,
      requestsList: [],
      reportsNum: 0,
      dropdownTitle: `Payment requests`,
      currentUserId: ``,
      limit: 50,
      skip: 0,
      disapproveBtnDisable: false,
      usersInfoArray: new Map(),
      hasUserData: false,
    };
  },
  computed: {
    paymentsList() {
      if (this.$store.state.payment_requests.data && this.hasUserData) {
        return this.$store.state.payment_requests.data;
      }
      return [];
    },
    getPaginationData() {
      return this.$store.state.pagination_object;
    },
  },
  watch: {
    getPaginationData(newVal, oldVal) {
      this.limit = newVal.limit;
      this.skip = newVal.skip;
      this.$store.dispatch(
        `GET_PAYMENT_REQUESTS`,
        `${this.sortStatusPending}&$limit=${this.limit}&$skip=${this.skip}`
      );
    },
    paymentsList(newVal, oldVal) {
      this.$store.commit(
        `SET_TOTAL_ITEMS_PAGINATION`,
        this.$store.state.payment_requests.total
      );
    },
  },
  mounted() {
    this.$store
      .dispatch(
        `GET_PAYMENT_REQUESTS`,
        `${this.sortStatusPending}&$limit=${this.limit}&$skip=${this.skip}`
      )
      .then(() => {
        let arr = [];
        this.$store.state.payment_requests.data.map((item) => {
          arr.push(item.accountId);
        });
        this.$store.dispatch(`GET_ALL_USERS_INFO`, arr).then(() => {
          this.usersInfoArray = this.$store.state.all_users_info;
          this.hasUserData = true;
        });
      });
  },
  methods: {
    // APPROVE PAYMENT REQUEST

    getApprove(payment, index) {
      const payloadObj = {
        id: payment.id,
        status: { status: "APPROVED" },
      };

      this.$store
        .dispatch("APPROVE_PAYMENT", payloadObj)
        .then(() => {
          if (this.$store.state.payment_approve.status !== `COMPLETED`) {
            this.approveError = true;
          }
          this.isApproved = true;
          this.$store.dispatch(
            `GET_PAYMENT_REQUESTS`,
            `${this.sortStatusPending}&$limit=${this.limit}&$skip=${this.skip}`
          );
          this.$store.dispatch(`SEND_MESSAGE`, {
            accountId: localStorage.getItem(`id`),
            toAccountId: payment.accountId,
            data: [
              {
                content: `Your cash withdrawal has been accepted and is on it’s way to your PayPal account.`,
                id: payment.accountId,
                type: `text`,
              },
            ],
          });
        })
        .catch((error) => {
          this.approveError = true;
        });
    },
    closeApprovePopup() {
      this.isApproved = false;
      this.approveError = false;
    },

    // DISAPPROVE PAYMENT REQUEST

    getDisapprovePopup(p, i) {
      this.requestId = p.id;
      this.currentUserId = p.accountId;
      this.disapprovalPopup = true;
      this.reason = ``;
      this.disapproveBtnDisable = false;
    },
    closeDisapprovePopup() {
      this.disapprovalPopup = false;
    },

    disapproveRequest(p, i) {
      const messageObj = {
        accountId: localStorage.getItem(`id`),
        toAccountId: this.currentUserId,
        data: [
          {
            content: `Your request to withdraw has been rejected due to excessive reports on your page or content. ${this.reason}`,
            id: this.currentUserId,
            type: `text`,
          },
        ],
      };

      const payloadObj = {
        id: this.requestId,
        status: {
          status: `REJECTED`,
        },
      };
      this.$store.dispatch(`APPROVE_PAYMENT`, payloadObj).then(() => {
        this.disapproveBtnDisable = true;
        this.disapprovalPopup = false;
        this.$store.dispatch(
          `GET_PAYMENT_REQUESTS`,
          `${this.sortStatusPending}&$limit=${this.limit}&$skip=${this.skip}`
        );
      });
      this.$store.dispatch(`SEND_MESSAGE`, messageObj);
    },
    showPendings() {
      this.hasUserData = false;
      this.$store
        .dispatch(
          "GET_PAYMENT_REQUESTS",
          `${this.sortStatusPending}&$limit=${this.limit}&$skip=${this.skip}`
        )
        .then(() => {
          let arr = [];
          this.$store.state.payment_requests.data.map((item) => {
            arr.push(item.accountId);
          });
          this.$store.dispatch(`GET_ALL_USERS_INFO`, arr).then(() => {
            this.usersInfoArray = this.$store.state.all_users_info;
            this.hasUserData = true;
          });
        });
      this.dropdownTitle = `Pending requests`;
    },
    showHistory() {
      this.hasUserData = false;
      this.$store
        .dispatch(
          "GET_PAYMENT_REQUESTS",
          `${this.sortStatusCompleted}&$limit=${this.limit}&$skip=${this.skip}`
        )
        .then(() => {
          let arr = [];
          this.$store.state.payment_requests.data.map((item) => {
            arr.push(item.accountId);
          });
          this.$store.dispatch(`GET_ALL_USERS_INFO`, arr).then(() => {
            this.usersInfoArray = this.$store.state.all_users_info;
            this.hasUserData = true;
          });
        });
      this.dropdownTitle = `Payments history`;
    },
    showRejected() {
      this.hasUserData = false;
      this.$store
        .dispatch(
          "GET_PAYMENT_REQUESTS",
          `${this.sortStatusRejected}&$limit=${this.limit}&$skip=${this.skip}`
        )
        .then(() => {
          let arr = [];
          this.$store.state.payment_requests.data.map((item) => {
            arr.push(item.accountId);
          });
          this.$store.dispatch(`GET_ALL_USERS_INFO`, arr).then(() => {
            this.usersInfoArray = this.$store.state.all_users_info;
            this.hasUserData = true;
          });
        });
      this.dropdownTitle = `Rejected requests`;
    },
    showFailed() {
      this.hasUserData = false;
      this.$store
        .dispatch(
          "GET_PAYMENT_REQUESTS",
          `${this.sortStatusFailed}&$limit=${this.limit}&$skip=${this.skip}`
        )
        .then(() => {
          let arr = [];
          this.$store.state.payment_requests.data.map((item) => {
            arr.push(item.accountId);
          });
          this.$store.dispatch(`GET_ALL_USERS_INFO`, arr).then(() => {
            this.usersInfoArray = this.$store.state.all_users_info;
            this.hasUserData = true;
          });
        });
      this.dropdownTitle = `Failed requests`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

img,
video {
  display: block;
  object-fit: cover;
}

.payments-approval {
  width: 100%;
}
.payment-request {
  width: 100%;
  margin-bottom: 1em;
  padding: 0 0 0.5em 0;
  font-size: $font-size-mobile;
  background-color: transparent;

  @include media-breakpoint-up(md) {
    font-size: $font-size-small;
    padding: 1em 1em 1em 1em;
    background-color: rgb(51, 51, 53);
  }
  @include media-breakpoint-up(lg) {
    font-size: $font-size-medium;
  }
}
.payment-request__avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid 1px transparent;

  @include media-breakpoint-up(md) {
    width: 130px;
    height: 130px;
  }
  @include media-breakpoint-up(lg) {
    width: 150px;
    height: 150px;
  }
}
.hidden {
  display: none;
}
.blocked {
  background-color: red;
}
.btn-info:focus {
  color: #000;
  border-color: transparent;
  box-shadow: none;
}
.approve-popup {
  width: 100%;
  height: 15em;
  padding: 1em;
  @include media-breakpoint-up(lg) {
    width: 20em;
    height: 15em;
    padding: 1em;
  }
  @include media-breakpoint-up(xl) {
  }
  @include media-breakpoint-up(xxl) {
  }
}
.reject-reason {
  width: 19em;
  height: 19em;
  padding: 1em;
  border: solid 1px transparent;
  @include media-breakpoint-up(md) {
    width: 22em;
    height: 22em;
    padding: 3em;
  }
  @include media-breakpoint-up(lg) {
  }
}
.reject-reason-btn {
  width: 7em;
}
.history-btn {
  width: 7em;
  margin-bottom: 1em;

  @include media-breakpoint-up(md) {
    width: 15em;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -1px;
    margin-bottom: 0.6em;
  }
}
.payment-date {
  font-weight: 300;
  font-style: italic;
  opacity: 0.5;
  border: solid 1px transparent;
}
.pagination-wrap {
  width: 100%;
  min-height: 4em;
}
</style>
