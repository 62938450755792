<template lang="html">
  <div class="admin-users-search">
    <div class="search-wrapper">
      <div class="search-form">
        <div class="search-btn" />
        <input
          v-model="searchValue"
          type="text"
          class="search-input form-control bg-dark text-white"
          placeholder="Search by username..."
          @input="searchUser()"
        >
      </div>
      <button
        type="button"
        class="btn btn-dark clear-search"
        @click="clearSearch"
      >
        Clear
      </button>
      <div
        v-if="searchValue.length>0"
        class="popup"
      >
        <AdminUsersList
          v-if="!userNotFound"
          :users="usersList"
          :modify-user="modifyUser"
          :modify-top-status="modifyTopStatus"
          :is-search="true"
        />
        <div
          v-if="userNotFound"
          class="user-not-fond"
        >
          User Not Found
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminUsersList from "./List"
export default {
  name: "AdminUsersSearchForm",
  components: {
    AdminUsersList,
  },
  props: {
    users: {
      type: Array,
      default: function () {}
    },
    modifyUser: {
      type: Function,
      default: function () {}
    },
    modifyTopStatus: {
      type: Function,
      default: function () {}
    }
  },
  data(){
    return{
      searchValue: '',
      userNotFound: false,
    }
  },
  computed: {
    usersList() {
      return this.$store.state.search_user.data;
    },
  },
  methods: {
    searchUser(){
      this.$store.dispatch(
        `FIND_USERS`,
        `?q=${this.searchValue}`
      )
      .then(() => {
          this.userNotFound = this.$store.state.search_user.data.length === 0;
      })
      .catch((err) => console.log(err));
    },
    clearSearch(){
      this.searchValue='';
    },
  },
}
</script>

<style lang="css" scoped>
.admin-users-search{
  padding-top: 20px;
  width: 80%;
  margin: 0 auto;
}
.search-wrapper{
  position: relative;
  display: flex;
  min-width: 400px;
}
.search-form{
  width: 85%;
}
.clear-search{
  width: 15%;
}
.search-btn {
  width: 38px;
  height: 38px;
  background-image: url("../../../assets/img/icon-search.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2.2em;
  opacity: 0.6;
  position: absolute;
  top:0;
  left: 0;
}
.search-input{
  text-align: center;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 5px 0 0 5px;
}
.popup{
  position: absolute;
  width: 100%;
  z-index: 2;
  background: #1d1d1d;
  max-height: 50vh;
  overflow: scroll;
  top:38px;
}
.user-not-fond{
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.clear-search{
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0 5px 5px 0;
  background:rgba(255,255,255,.5);
  border-bottom: 1px solid #ced4da;
  color: #333335;
}
</style>
