<template>
  <footer class="footer w-100 bg-transparent">
    <section
      id="footer_links-wrap"
      class="d-flex justify-content-between mx-auto"
    >
      <a
        href="#"
        class="footer-link"
      >Brij &copy; {{ new Date().getFullYear() }} All rights reserved</a>
      <a
        href="https://brij.im/terms-and-conditions-for-brij"
        class="footer-link"
      >
        Terms and conditions
      </a>
      <a
        href="https://brij.im/privacy-policy"
        class="footer-link"
      >
        Privacy policy
      </a>
    </section>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

.footer {
  height: 50px;
}
#footer_links-wrap {
  width: 100%;
  padding: 10px;
  border: solid 1px transparent;
  @include media-breakpoint-up(md) {
    width: 60%;
    padding: 10px 54px 10px 10px;
  }
  @include media-breakpoint-up(lg) {
    width: 700px;
    padding: 10px 72px 10px 10px;
  }
}
#footer_links-wrap a {
  font-size: $font-size-mobile;
  @include media-breakpoint-up(md) {
    font-size: $font-size-small;
  }
}

.footer-link {
  color: #fff;
  text-decoration: none;
  opacity: 0.5;
}
.footer-link:hover {
  color: #fff;
  text-decoration: underline;
  opacity: 0.5;
}
</style>
