<template>
  <section class="dashboard container-fluid">
    <header
      class="header fixed-top bg-transparent d-flex flex-row justify-content-between align-items-center container-fluid p-3"
    >
      <router-link
        class="logo d-flex justify-content-between"
        to="/admin-panel"
        exact
      >
        <img
          src="../../../assets/img/icon-logo.png"
          alt="logo"
          width="38"
          height="38"
        >
      </router-link>
      <h1 class="title h2 text-white">
        Admin panel
      </h1>
      <div class="gear-wrap d-flex justify-content-between align-items-center">
        <span class="username-title text-white">{{ getProfileData.name || username }}</span>
        <div class="dropdown">
          <button
            id="dropdownMenuButton"
            class="dashboard__settings"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div
            id="my-profile__dropdown-menu"
            class="dropdown-menu bg-transparent border"
            aria-labelledby="dropdownMenuButton"
          >
            <button
              type="button"
              class="dropdown-item btn btn-sm text-white"
              @click="logout"
            >
              Log Out
            </button>
          </div>
        </div>
      </div>
    </header>
    <section class="links-wrapper container-fluid">
      <router-link
        :to="{name:'AdminUsersAll'}"
        class="h3 dashboard-link"
      >
        Users
      </router-link>
      <router-link
        :to="{name:'AdminPosts'}"
        class="h3 dashboard-link"
      >
        Posts
      </router-link>
      <router-link
        :to="{name:'AdminPayments'}"
        class="h3 dashboard-link"
      >
        Payments
      </router-link>
      <router-link
        :to="{name:'AdminReports'}"
        class="h3 dashboard-link"
      >
        Reports
      </router-link>
    </section>
    <Footer class="mt-3" />
  </section>
</template>

<script>
import Footer from "../../Partials/Footer";
import { USER_DEFAULT_NAME } from "../../../constants";
export default {
  name: "AdminDashboard",
  components: {
    Footer
  },
  data() {
    return {
      username: USER_DEFAULT_NAME,
    };
  },
  computed: {
    getProfileData() {
      return this.$store.state.my_profile_info;
    },
  },
  mounted() {
    this.$store.dispatch("GET_PROFILE_INFO", `/${localStorage.getItem(`id`)}`);
  },
  methods: {
    logout() {
      this.$store
        .dispatch("LOGOUT")
        .then(() => this.$router.push("/"))
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

.dashboard {
  min-height: 100vh;
  background-color: $bgColorMain;
  background-image: url("../../../assets/img/withdraw-bg.png");
  background-size: cover;
}

.logo {
  @include media-breakpoint-up(md) {
    width: 30%;
  }
}

.title {
  text-align: center;
  margin: 0px 0px 0px 0px;

  @include media-breakpoint-up(md) {
    width: 30%;
  }
}
.gear-wrap {
  @include media-breakpoint-up(md) {
    width: 30%;
  }
}
.username-title {
  font-size: $font-size-mobile;

  @include media-breakpoint-up(md) {
    font-size: $font-size-large;
    margin: 0px 0px 6px auto;
  }
}
.links-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: calc(100vh - 52px);
  padding: 70px 0px 0px 0px;
  border: solid 1px transparent;
}
.dashboard-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13em;
  height: 4em;
  text-decoration: none;
  background-color: $bgColorMain;
  background-position: left 25% center;
  background-repeat: no-repeat;
  border: solid 1px rgb(218, 201, 201);
  border-radius: 5px;
  margin-top: 30px;
  padding: 30px;
  color: white;
  -webkit-box-shadow: 5px -5px 20px 5px rgba(92,197,222,0.72);
  box-shadow: 5px -5px 20px 5px rgba(92,197,222,0.72);
  }

.dashboard-link:hover {
  background-color: $mainBlue;
  opacity: 0.9;
}

.dashboard-link:nth-child(1) {
  background-image: url("../../../assets/img/icon-users.svg");
    background-size: 44px 44px;
}

.dashboard-link:nth-child(2) {
    background-image: url("../../../assets/img/icon-posts.svg");
    background-size: 42px 42px;
}

.dashboard-link:nth-child(3) {
    background-image: url("../../../assets/img/icon-dolar.svg");
    background-size: 44px 44px;
}
.dashboard-link:nth-child(4) {
    background-image: url("../../../assets/img/icon-flag.svg");
    background-size: 44px 44px;
}
.dashboard__settings {
  width: 50px;
  height: 50px;
  border: none;
  background: transparent;
  background-image: url("../../../assets/img/icon-gear.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35px 35px;
}
.header {
  height: 72px;
  @include media-breakpoint-up(lg) {
    height: 70px;
  }
}
#my-profile__dropdown-menu {
  min-width: 6rem;
  transform: translate(-48%, 97%) !important;

  @include media-breakpoint-up(md) {
    min-width: 6rem;
    transform: translate(-35%, 140%) !important;
  }
}
.dropdown-item:hover {
  color: #000000 !important;
}
.dropdown-item:active {
  background-color: grey !important;
}
</style>
