<template>
  <section
    class="
      wrap
      d-flex
      flex-column
      justify-content-center
      align-items-center
      pt-5
    "
  >
    <form
      class="
        edit-form
        d-flex
        flex-column
        justify-content-start
        align-items-center
        p-5
        mt-5
        position-relative
        border
      "
      @submit.prevent="saveInfo"
    >
      <input
        v-model="name"
        class="bg-dark border w-100 mt-5 text-white"
        type="text"
        :placeholder="getProfileData.name || 'Your name..'"
      >
      <textarea
        v-model="bio"
        class="bg-dark border w-100 mt-5 text-white"
        rows="6"
        :placeholder="getProfileData.bio || 'Your bio...'"
      />
      <div class="w-100 d-flex justify-content-between mt-5">
        <button
          class="btn btn-info"
          type="submin"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="goBack()"
        >
          Cancel
        </button>
      </div>

      <section
        v-if="isError"
        class="
          error-popup
          position-absolute
          top-0
          end-0
          w-100
          h-100
          bg-dark
          d-flex
          flex-column
          justify-content-center
          align-items-center
          p-3
        "
      >
        <h3 class="h3 text-white text-center">
          This service is not available at this time. Please try again later.
        </h3>
        <router-link
          to="/profile"
          class="btn btn-secondary mt-5"
        >
          Back to my profile
        </router-link>
      </section>
    </form>
  </section>
</template>

<script>
require(`dotenv`).config();

export default {
  name: "EditProfile",
  data() {
    return {
      avatar: ``,
      avatarLink: ``,
      name: ``,
      bio: ``,
      isError: false,
      imgSrc: ``,
      avatarPreviewShow: false,
      avatarLabelShow: true,
      selectedFile: ``,
    };
  },
  computed: {
    getProfileData() {
      return this.$store.state.my_profile_info;
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "GET_PROFILE_INFO",
      `/${localStorage.getItem(`id`)}`
    );

    this.name = this.getProfileData.name;
    this.bio = this.getProfileData.bio;
  },
  methods: {
    saveInfo() {
      const dataObj = {
        name: this.name || this.$store.state.my_profile_info.name,
        bio: this.bio || this.$store.state.my_profile_info.bio,
      };
      this.$store
        .dispatch(`MODIFY_USER`, {
          id: localStorage.getItem(`id`),
          status: dataObj,
        })
        .then(() => {
          this.$router.push(`/content`);
        })
        .catch((err) => {
          console.log(err.response);
          this.isError = true;
        });
    },
    goBack() {
      this.$router.push("/content");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 90vh;
  border: solid 1px transparent;
}

.edit-form {
  width: 100%;
  height: 100%;
  background-color: rgb(33, 33, 36);
  @include media-breakpoint-up(md) {
    width: 15em;
  }
  @include media-breakpoint-up(lg) {
    width: 25em;
  }
}
.avatar-upload {
  width: 7em;
  height: 7em;
  border-radius: 50%;
  cursor: pointer;
  @include media-breakpoint-up(md) {
    width: 8em;
    height: 8em;
  }
  @include media-breakpoint-up(lg) {
    width: 9em;
    height: 9em;
  }
}
.avatar-upload:hover {
  background-color: rgb(42, 42, 46);
}
.avatar-preview {
  width: 7em;
  height: 7em;
  border-radius: 50%;
  @include media-breakpoint-up(md) {
    width: 8em;
    height: 8em;
  }
  @include media-breakpoint-up(lg) {
    width: 9em;
    height: 9em;
  }
}
.hidden {
  display: none;
}
.btn {
  width: 6.7em;
}
</style>
