<template>
  <section class="feed-page">
    <div
      id="post-list"
      class="post-list"
    >
      <div
        v-for="(post, index) in feedList"
        :key="index"
        class="post-card mx-auto mt-5"
      >
        <div class="post-card__user-info mt-2 mb-2 ms-1">
          <div class="post-card__user-wrap">
            <img
              class="border border-secondary rounded-circle" 
              :src="getAvatarUrl(post.accountId)"
              alt="avatar"
              width="60"
              height="60"
            >
            <span
              v-if="showUsername"
              class="post-card__user-name"
            >{{
              usersInfoArray.get(post.accountId).username
            }}</span>
          </div>
        </div>
        <div class="pic-wrap">
          <FeedCard :my-props="{postData: post, count: index}" />
        </div>
        <span class="post-card__post-name text-white mt-2 ms-1">{{
          post.name
        }}</span>
        <span class="post-card__description ms-1">{{ post.content }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import FeedCard from "./FeedCard.vue";
export default {
  name: "Feeds",
  components: { FeedCard },

  data() {
    return {
      usersInfoArray: new Map(),
      showUsername:false
    };
  },
  computed: {
    feedList() {
      return this.$store.state.feed_posts.data;
    },
  },
  mounted() {
    this.$store.dispatch(`GET_FEED`).then(() => {
      setTimeout(() => {
        let arr = [];
        this.$store.state.feed_posts.data.map((item) => {
          arr.push(item.accountId);
        });
        this.$store.dispatch(`GET_ALL_USERS_INFO`, arr).then(() => {
          this.usersInfoArray = this.$store.state.all_users_info;
          this.showUsername = true;
        });
      }, 1000);
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

.post-list {
  width: 100%;
  background-color: $bgColorMain;
  padding-top: 3em;
  padding-bottom: 3em;
  border: solid 1px transparent;
}

img,
video {
  display: block;
  object-fit: cover;
}
[tabindex] {
  outline: none !important;
}
.post-card {
  width: 290px;
  box-sizing: border-box;
  background-color: rgb(51, 51, 53);
  border: solid 1px transparent;
  padding-bottom: 1em;
  @include media-breakpoint-up(md) {
    width: 600px;
  }
  @include media-breakpoint-up(lg) {
    width: 600px;
  }
  @include media-breakpoint-up(xl) {
    width: 600px;
  }
  @include media-breakpoint-up(xxl) {
    width: 600px;
  }
}
.pic-wrap {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 100%;
  }
  @include media-breakpoint-up(lg) {
    width: 100%;
  }
}
.post-card__user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: solid 1px transparent;
}
.post-card__user-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
}
.post-card__user-name {
  font-style: normal;
  line-height: 18px;
  color: #ffffff;
  margin-left: 1em;
}
.post-card__post-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: solid 1px transparent;
}
.post-card__description {
  display: block;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  color: #ffffff;
  border: solid 1px transparent;
  margin-top: 5px;
  word-break: break-all;
}
.post-video {
  width: 100%;
  height: 100%;
}
</style>