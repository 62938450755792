<template>
  <div class="d-flex justify-content-between w-100 h-100">
    <nav aria-label="Page navigation">
      <ul class="pagination">
        <!-- PAGINATION BUTTONS START -->
        <li class="page-item">
          <button
            class="page-link bg-dark text-white"
            aria-label="Previous"
            :disabled="prevBtnDisabled"
            @click="showPreviousUsers"
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        <li
          v-for="(btn, index) in getPaginationButtonsList"
          v-show="index < 5 || index > paginationLength - 2"
          :key="index"
          class="page-item"
        >
          <div class="d-flex">
            <button
              v-show="paginationLength > 5 && index > paginationLength - 2"
              class="page-link bg-dark text-white"
              @click="showNextUsers"
            >
              ...
            </button>

            <button
              class="page-link bg-dark text-white"
              :class="{ 'link-highlighted': index == indexBtn }"
              @click="showItemByPagination(btn, index)"
            >
              {{ index + 1 }}
            </button>
          </div>
        </li>
        <li class="page-item">
          <button
            class="page-link bg-dark text-white"
            aria-label="Next"
            :disabled="nextBtnDisabled"
            @click="showNextUsers"
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
        <!-- PAGINATION BUTTONS END -->
      </ul>
    </nav>
    <div class="droplist-wrap">
      <div class="form-group">
        <select
          id="exampleFormControlSelect1"
          v-model="selectedLimitPerPage"
          class="form-control bg-dark text-white"
          @change="showSelectedAmount"
        >
          <option>12</option>
          <option>24</option>
          <option>50</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
const getButtonsList = (a, b) => {
  return Math.ceil(a / b);
};
export default {
  name: "PaginationNavigation",
  data() {
    return {
      selectedLimitPerPage: 50,
      paginationLength: 0,
      pageSize: 0,
      skipQtty: 0,
      prevBtnDisabled: false,
      nextBtnDisabled: false,
      indexBtn: 0
    };
  },
  computed: {
    getPaginationButtonsList() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.paginationLength = getButtonsList(
        this.$store.state.total_items_pagination,
        this.selectedLimitPerPage
      );
      return getButtonsList(this.$store.state.total_items_pagination, this.selectedLimitPerPage);
    },
  },
  methods: {
    showPreviousUsers() {
      if (this.skipQtty > 0) {
        this.skipQtty -= this.selectedLimitPerPage;
        this.indexBtn--;
      } else if (this.skipQtty <= 0) {
        this.prevBtnDisabled = true;
        this.nextBtnDisabled = false;
        this.skipQtty = 0;
      }

      this.$store.commit(`SET_PAGINATION`, {
        limit: this.selectedLimitPerPage,
        skip: this.skipQtty,
      });
    },

    showNextUsers() {
      if (
        this.skipQtty < this.$store.state.total_items_pagination &&
        this.$store.state.total_items_pagination - this.selectedLimitPerPage > this.skipQtty
      ) {
        this.nextBtnDisabled = false;
        this.skipQtty += parseInt(this.selectedLimitPerPage);
        this.indexBtn++;
      } else {
        this.prevBtnDisabled = false;
        this.nextBtnDisabled = true;
      }

      this.$store.commit(`SET_PAGINATION`, {
        limit: this.selectedLimitPerPage,
        skip: this.skipQtty,
      });
    },

    showSelectedAmount() {
      this.skipQtty = 0;
      this.$store.commit(`SET_PAGINATION`, {
        limit: this.selectedLimitPerPage,
        skip: this.skipQtty,
      });
    },

    showItemByPagination(btn, index) {
      this.indexBtn = index;
      this.prevBtnDisabled = false;
      this.nextBtnDisabled = false;
      this.skipQtty = index * this.selectedLimitPerPage;
      this.$store.commit(`SET_PAGINATION`, {
        limit: this.selectedLimitPerPage,
        skip: this.skipQtty,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

.link-highlighted {
  background-color: $mainBlue !important;
}
</style>