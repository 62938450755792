<template>
  <section>
    <MyProfile />
  </section>
</template>

<script>
import MyProfile from "../components/MyProfile/MyProfile.vue";
export default {
  name: "MyPageUser",
  components: {
    MyProfile,
  },
};
</script>