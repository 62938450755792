<template>
  <section class="container-fluid pt-0 pb-5">
    <nav
      id="admin-navigation"
      class="
        fixed-top
        navbar navbar-expand-lg
        bg-dark
        p-3
        navbar-dark
        justify-content-between
      "
    >
      <router-link
        to="/admin-panel"
        class="admin__logo"
      >
        <img
          src="../../../assets/img/icon-logo.png"
          alt="logo"
          width="38"
          height="38"
        >
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div
        id="navbarNavAltMarkup"
        class="collapse navbar-collapse justify-content-between"
      >
        <div class="navbar-nav justify-content-between">
          <router-link
            id="admin__users-btn"
            :to="{name:'AdminUsersAll'}"
            class="btn nav-link text-white"
            active-class="link-highlighted"
          >
            Users
          </router-link>
          <router-link
            id="admin__posts-btn"
            :to="{name:'AdminPosts'}"
            class="btn nav-link text-white"
            active-class="link-highlighted"
          >
            Posts
          </router-link>
          <router-link
            id="admin__payments-btn"
            :to="{name:'AdminPayments'}"
            class="btn nav-link text-white"
            active-class="link-highlighted"
          >
            Payments
          </router-link>
          <router-link
            id="admin__reports-btn"
            :to="{name:'AdminReports'}"
            class="btn nav-link text-white"
            active-class="link-highlighted"
          >
            Reports
          </router-link>
          <button
            id="admin__logout-btn"
            type="button"
            class="btn nav-link text-white"
            @click="logout"
          >
            <span class="link-title">Log Out</span>
          </button>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
export default {
  name: "AdminGeneralTopBar",
  methods: {
    logout() {
      this.$store
        .dispatch("LOGOUT")
        .then(() => this.$router.push("/"))
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

// .admin {
//   background-color: $bgColorMain;
// }
.admin__page {
  border: solid 1px transparent;
}
.admin__logo {
  cursor: pointer;
  @include media-breakpoint-up(lg) {
    width: 12%;
  }
}
.admin__payments-approval {
  border: solid 1px transparent;
}

.admin-title {
  display: block;
  text-align: center;
  margin: 0px 0px 0px 0px;

  @include media-breakpoint-up(lg) {
    width: 10%;
  }
}

.username-title {
  font-size: $font-size-large;
}

.hidden {
  display: none;
}
#admin__users-btn,
#admin__posts-btn,
#admin__payments-btn,
#admin__reports-btn,
#admin__logout-btn,
.dash-link {
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 8em;
    height: 40px;
    padding: 0px 27px 0px 0px !important;
  }
}
#admin__users-btn {
  @include media-breakpoint-up(lg) {
    background-image: url("../../../assets/img/icon-users.svg");
    background-position: 7px 0px;
    background-repeat: no-repeat;
    background-size: 34px 34px;
  }
}
#admin__posts-btn {
  @include media-breakpoint-up(lg) {
    background-image: url("../../../assets/img/icon-posts.svg");
    background-position: 7px 0px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
  }
}
#admin__payments-btn {
  @include media-breakpoint-up(lg) {
    background-image: url("../../../assets/img/icon-dolar.svg");
    background-position: -1px 3px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
  }
}
#admin__reports-btn {
  @include media-breakpoint-up(lg) {
    background-image: url("../../../assets/img/icon-flag.svg");
    background-position: 4px 3px;
    background-repeat: no-repeat;
    background-size: 27px 30px;
  }
}
#admin-navigation {
  @include media-breakpoint-up(lg) {
    max-height: 70px;
  }
}
.dash-link {
  @include media-breakpoint-up(lg) {
    background-image: url("../../../assets/img/icon-dashboard.svg");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
  }
}
#admin__logout-btn {
  @include media-breakpoint-up(lg) {
    background-image: url("../../../assets/img/icon-exit.svg");
    background-position: -4px 1px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    margin: 0px 0px 0px 0px;
  }
}
.link-title {
  @include media-breakpoint-up(lg) {
    font-size: $font-size-medium;
    display: inline-block;
    margin: 0px 0px 0px 0px;
  }
}
.link-highlighted {
  background-color: $mainBlue;
}
.navbar-nav {
  @include media-breakpoint-up(lg) {
    width: 100%;
  }
}
.pagination-wrap {
  width: 100%;
  min-height: 4em;
}
.active-users-link #admin__users-btn{
  background-color: #5CC5DE;
}
</style>
