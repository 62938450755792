<template>
  <nav class="tabs nav nav-tabs my-profile__btn-wrap w-100 bg-transparent">
    <button
      v-for="(tab, index) in tabs"
      :key="index"
      :class="[
        'nav-link btn-sm',
        { 'text-black active': tab.active, 'text-white': tab.color },
      ]"
      @click="setActive(tab, index)"
    >
      {{ tab.caption }}
    </button>
  </nav>
</template>

<script>
export default {
  name: "TabGroup",
  data() {
    return {
      tabs: [
        {
          caption: "My content",
          active: true,
          color: false,
        },
        {
          caption: "Purchased",
          active: false,
          color: true,
        },
        {
          caption: "Saved",
          active: false,
          color: true,
        },
      ],
    };
  },
  methods: {
    setActive(tab, i) {
      const contentObj = {
        mycontent: false,
        purchased: false,
        saved: false,
      };

      this.tabs.forEach((el) => {
        el.active = el === tab;
        el.color = el !== tab;
      });
      switch (tab.caption) {
        case `My content`:
          contentObj.mycontent = true;
          break;
        case `Purchased`:
          contentObj.purchased = true;
          break;
        case `Saved`:
          contentObj.saved = true;
        default:
          true;
      }
      this.$store.dispatch("GET_PROFILE_CONTENT", contentObj);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

.my-profile__btn-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;

  @include media-breakpoint-up(md) {
    margin-top: 20px;
  }
}
</style>