<template>
  <section class="post-page d-flex flex-column text-white container pb-5">
    <button
      class="btn btn-secondary btn-lg mt-5 ps-5 align-self-start"
      @click="goBack() ? $router.go(-1) : $router.push('/')"
    >
      Back
    </button>
    <span class="mt-3">{{ name }}</span>
    <span
      v-if="content"
      class="mt-3"
    >{{ content }}</span>
    <span class="mt-3">Created at {{ getDate(created) }}</span>
    <span class="mt-3">Updated at {{ getDate(updated) }}</span>

    <div
      v-if="status == 'REPORTED'"
      class="mt-3 text-danger"
    >
      <span>{{ status }}:
        {{
          userReportsQtty[this.$router.currentRoute.path.split(`/`).pop()]
        }}</span>
      <div
        v-for="reasonData in reportReason"
        :key="reasonData"
        class="mt-3 text-danger"
      >
        <span
          v-for="(reason, index) in reasonData.data"
          :key="reason"
        >{{ reason.reason
        }}<span v-show="index < reasonData.data.length - 1">, </span>
        </span>
      </div>
    </div>

    <div class="row row-cols-auto row-cols-md-3 mt-3">
      <div
        v-for="mediaItem in postMedia"
        :key="mediaItem"
        class="col p-3"
      >
        <img
          v-if="mediaItem.type == `image`"
          :src="getPostImageUrl(postId, mediaItem.id)"
          class="img-thumbnail"
          alt="image"
        >
        <video
          v-else
          controls
          width="250"
          class="border p-1"
        >
          <source
            :src="getPostVideoUrl(postId, mediaItem.id)"
            type="video/webm"
          >

          <source
            :src="getPostVideoUrl(postId, mediaItem.id)"
            type="video/mp4"
          >

          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PostPage",
  data() {
    return {
      id: this.$router.currentRoute.path,
      accountId: ``,
      content: ``,
      created: ``,
      icon: ``,
      postId: ``,
      name: ``,
      status: ``,
      statusMessage: ``,
      updated: ``,
      reportedCount: 0,
      userReportsQtty: {},
      reportReason: [],
      postMedia: [],
    };
  },
  mounted() {
    let id = this.$router.currentRoute.path.split(`/`).pop();

    this.$store.dispatch(`GET_POSTS`, `?id=${id}`).then(() => {
      this.accountId = this.$store.state.posts.data[0].accountId;
      this.content = this.$store.state.posts.data[0].content;
      this.created = this.$store.state.posts.data[0].created;

      this.icon = this.getPostUrl(this.$store.state.posts.data[0]);

      setTimeout(() => {
        this.$store.state.posts.data.map((item) => {
          this.postMedia = item.media;
        });
      }, 500);

      this.postId = this.$router.currentRoute.path.split(`/`).pop();
      this.name = this.$store.state.posts.data[0].name;
      this.status = this.$store.state.posts.data[0].status;
      this.statusMessage =
        this.$store.state.posts.data[0].statusMessage || `No status message`;
      this.updated = this.$store.state.posts.data[0].updated;
      this.reportedCount = this.$store.state.posts.data[0].reportedCount;

      let ids = [];

      this.$store.state.posts.data.map((item) => {
        ids.push(item.id);
      });
      this.getReportsCount({ type: `POST`, storeArr: ids });
    });
  },
  methods: {
    /// GET REPORTS
    getReportsCount(arg) {
      let payload = {
        ids: arg.storeArr,
        subject: arg.type,
      };
      this.$store.dispatch(`GET_REPORTS`, payload).then(() => {
        this.userReportsQtty = this.$store.state.reports;
      });
      this.$store
        .dispatch(
          `GET_REPORTS_ARRAY`,
          `?targetId=${this.$router.currentRoute.path.split(`/`).pop()}`
        )
        .then(() => {
          this.reportReason.push(this.$store.state.reports_array);
        });
    },
    goBack() {
      this.$store.state.posts = [];
      return window.history.length > 2;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

img,
video {
  width: 100%;
  height: auto;
  min-height: 300px;
  object-fit: scale-down;
}
.post-page {
  font-size: $font-size-small;
  font-weight: 300;

  @include media-breakpoint-up(md) {
    font-size: $font-size-medium;
  }
}
.btn {
  background-image: url("../assets/img/previous.svg");
  background-repeat: no-repeat;
  background-position: 10% 30%;
  background-size: 1.5em 1.5em;
}
</style>