<template>
  <div
    :id="`carouselExampleControls${getCount}`"
    class="carousel slide"
    :class="{blurred: getPostData.isBlurred}"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner h-100">
      <div
        v-for="(mediaItem, index) in getPostData.media"
        :key="mediaItem.id"
        class="carousel-item  h-100"
        :class="{ active: index == 0 }"
      >
        <img
          v-if="mediaItem.type == 'image'"
          :src="getPostImageUrl(getPostData.id, mediaItem.id)"
          class="d-block w-100  h-100"
          alt="image"
        >
        <video
          v-else-if="mediaItem.type == 'video'"
          width="320"
          height="240"
          controls
          muted
          class="d-block w-100 h-100"
        >
          <source
            :src="getPostVideoUrl(getPostData.id, mediaItem.id)"
            type="video/mp4"
          >
          <source
            :src="getPostVideoUrl(getPostData.id, mediaItem.id)"
            type="video/ogg"
          >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      :data-bs-target="`#carouselExampleControls${getCount}`"
      data-bs-slide="prev"
    >
      <span
        class="carousel-control-prev-icon"
        aria-hidden="true"
      />
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      :data-bs-target="`#carouselExampleControls${getCount}`"
      data-bs-slide="next"
    >
      <span
        class="carousel-control-next-icon"
        aria-hidden="true"
      />
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    myProps: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getPostData() {
      return this.myProps.postData;
    },
    getCount() {
      return this.myProps.count;
    },
  },
};
</script>

<style lang="scss" scoped>
img,
video {
  display: block;
  object-fit: cover;
}
.carousel {
  height: 630px;
}
.blurred {
  filter: blur(1.5rem);
}
</style>