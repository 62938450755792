import Vue from 'vue';
import VueRouter from 'vue-router';
import StartPage from '../views/StartPage';
import MyPageUser from "../views/MyPageUser";
import ResetPasswordForm from '../components/Partials/ResetPasswordForm';
import AdminDashboard from '../components/Admin/General/Dashboard';
import NotFound from '../views/NotFound.vue';
import WithdrawPage from '../views/WithdrawPage';
import AdminUsersAll from '../components/Admin/Users/All';
import AdminUsersPrivate from '../components/Admin/Users/Private';
import AdminUsersBlocked from '../components/Admin/Users/Blocked';
import AdminUsersTop from '../components/Admin/Users/Top';
import AdminPosts from '../components/Admin/Posts/Posts';
import AdminPayments from '../components/Admin/Payments/PaymentsApproval';
import AdminReports from '../components/Admin/Reports/Reports';
import {
  store
} from '../store/index.js';
import SetNewPassword from '../views/SetNewPassword';
import EditProfile from '../views/EditProfile';
import UserProfile from '../views/UserProfile';
import PostPage from '../views/PostPage';
import Feeds from "../components/MyProfile/Feeds";

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [{
      path: '/',
      name: 'StartPage',
      component: StartPage,
      meta: {
        requiresAuth: false,
        adminAuth: false,
        residentAuth: false
      }
    },
    {
      path: '/reset-password',
      name: 'ResetPasswordForm',
      component: ResetPasswordForm
    },
    {
      path: '/new-password',
      name: 'SetNewPassword',
      component: SetNewPassword
    },
    {
      path: '/content',
      name: 'MyPageUser',
      component: MyPageUser,
      meta: {
        requiresAuth: true,
        adminAuth: false,
        residentAuth: true
      }
    },
    {
      path: '/content/feed',
      name: 'Feeds',
      component: Feeds,
      meta: {
        requiresAuth: true,
        adminAuth: false,
        residentAuth: true
      }
    },
    {
      path: '/content/my/edit',
      name: 'EditProfile',
      component: EditProfile,
      meta: {
        requiresAuth: true,
        adminAuth: false,
        residentAuth: true
      }
    },
    {
      path: '/profile/:id',
      name: 'UserProfile',
      component: UserProfile,
      meta: {
        requiresAuth: true,
        adminAuth: true,
        residentAuth: false
      }
    },
    {
      path: '/post/:id',
      name: 'PostPage',
      component: PostPage,
      meta: {
        requiresAuth: true,
        adminAuth: true,
        residentAuth: false
      }
    },
    {
      path: '/admin-panel',
      name: 'AdminDashboard',
      component: AdminDashboard,

      meta: {
        requiresAuth: true,
        adminAuth: true,
        residentAuth: true
      }
    },
    {
      path: '/withdraw',
      name: 'WithdrawPage',
      component: WithdrawPage,
      meta: {
        requiresAuth: true,
        adminAuth: false,
        residentAuth: true
      }
    },
    {
      path: '*',
      name: 'Not Found',
      component: NotFound
    },
    {
      path: '/admin/users/all',
      name: 'AdminUsersAll',
      component: AdminUsersAll,

      meta: {
        requiresAuth: true,
        adminAuth: true,
        residentAuth: true
      }
    },
    {
      path: '/admin/users/private',
      name: 'AdminUsersPrivate',
      component: AdminUsersPrivate,

      meta: {
        requiresAuth: true,
        adminAuth: true,
        residentAuth: true
      }
    },
    {
      path: '/admin/users/blocked',
      name: 'AdminUsersBlocked',
      component: AdminUsersBlocked,

      meta: {
        requiresAuth: true,
        adminAuth: true,
        residentAuth: true
      }
    },
    {
      path: '/admin/users/top',
      name: 'AdminUsersTop',
      component: AdminUsersTop,

      meta: {
        requiresAuth: true,
        adminAuth: true,
        residentAuth: true
      }
    },
    {
      path: '/admin/posts',
      name: 'AdminPosts',
      component: AdminPosts,

      meta: {
        requiresAuth: true,
        adminAuth: true,
        residentAuth: true
      }
    },
    {
      path: '/admin/payments',
      name: 'AdminPayments',
      component: AdminPayments,

      meta: {
        requiresAuth: true,
        adminAuth: true,
        residentAuth: true
      }
    },
    {
      path: '/admin/reports',
      name: 'AdminReports',
      component: AdminReports,

      meta: {
        requiresAuth: true,
        adminAuth: true,
        residentAuth: true
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!localStorage.getItem(`id`)) {
      next('/')
    } else if (to.meta.adminAuth) {
      if (store.state.is_admin) {
        next()
      } else {
        next('/content/my')
      }
    } else if (to.meta.residentAuth) {
      if (!store.state.is_admin) {
        next()
      } else {
        next('/admin-panel')
      }
    }
  } else {
    next()
  }
})

export default router
