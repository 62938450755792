
<template>
  <section
    class="
      reset-page
      text-center
      container-fluid
      p-0
      d-flex
      flex-column
      justify-content-between
      align-items-center
      pt-5
    "
  >
    <form
      autocomplete="off"
      class="form px-5 py-5 position-relative text-white"
      @submit.prevent="reset"
    >
      <h2 class="m-0">
        Forgot Password?
      </h2>
      <p class="m-0">
        You can reset your password here.
      </p>

      <input
        id="email"
        v-model="email"
        name="email"
        placeholder="email address"
        class="form-control"
        type="email"
        required
        @change="isError = false"
      >
      <span
        v-if="isError"
        class="alert alert-warning position-absolute"
      >
        {{ errorMessage }}
      </span>
      <button
        class="btn btn-secondary"
        value="Reset Password"
        type="submit"
      >
        <span>Reset password</span>
      </button>
      <router-link
        class="btn btn-link btn-sm"
        to="/"
      >
        Back to Log In
      </router-link>
    </form>
    <section
      v-if="isModalShow"
      class="
        reset-modal
        d-flex
        flex-column
        justify-content-center
        align-items-center
        position-absolute
        top-0
        start-0
        w-100
        bg-dark
        p-5
      "
    >
      <p class="fs-4 text-white">
        A password reset code was sent.
      </p>
      <p class="fs-4 text-white">
        Use the code in the email to create a new password on next page.
      </p>
      <p class="fs-4 text-white">
        If you did not receive the email, please use this link:
      </p>
      <button
        type="button"
        class="btn btn-link fs-4"
        @click="resendToken"
      >
        Re-send code
      </button>
      <router-link
        to="/new-password"
        class="btn btn-secondary mt-3"
      >
        Set new password
      </router-link>
      <div
        v-if="showToast"
        class="
          position-absolute
          top-50
          start-50
          translate-middle
          d-flex
          justify-content-between
          w-25
          bg-dark
          fs-5
          text-white
          p-3
        "
      >
        <span>Your code has been sent!</span>
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          @click="closeToast"
        />
      </div>
    </section>
    <Footer />
  </section>
</template>

<script>
import Footer from "../Partials/Footer";

export default {
  name: "ResetPasswordForm",
  components: {
    Footer,
  },

  data() {
    return {
      existingUsers: [],
      isError: false,
      isModalShow: false,
      errorMessage: ``,
      email: ``,
      showToast: false,
      dataToSend: {}
    };
  },
  methods: {
    reset() {
      localStorage.setItem(`email`, this.email);
      const objToSend = {
        action: `sendResetPwd`,
        value: { email: this.email },
      };
      this.dataToSend = objToSend;
      this.$store
        .dispatch("RESET_PASSWORD", objToSend)
        .then(() => {
          this.isModalShow = true;
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.errorMessage = error.response.data.message;
          this.isError = true;
        });
    },
    resendToken() {
      this.$store.dispatch("RESET_PASSWORD", this.dataToSend);
      this.showToast = true;
    },
    closeToast() {
      this.showToast = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 400px;
  @include media-breakpoint-up(md) {
    width: 400px;
  }
}
.hidden {
  display: none !important;
}
.reset-page,
.reset-modal {
  min-height: 100vh;
  background-image: url("../../assets/img/welcome-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.alert {
  width: 70%;
  transform: translate(0%, 120%);
  @include media-breakpoint-up(md) {
    width: 77%;
  }
  @include media-breakpoint-up(lg) {
    width: 76%;
  }
  @include media-breakpoint-up(xl) {
    width: 77%;
  }
  @include media-breakpoint-up(xxl) {
    width: 78%;
  }
}
</style>
