<template>
  <section class="position-relative">
    <div
      v-if="isModal"
      class="
        withdraw-modal
        position-absolute
        top-0
        start-50
        translate-middle
        d-flex
        flex-column
        align-items-center
        p-3
        text-white
        bg-dark
      "
    >
      You can withdraw amount starting $10
      <button
        type="button"
        class="btn btn-info mt-3"
        @click="closeModal"
      >
        Ok
      </button>
    </div>
    <div class="my-profile container">
      <div class="my-profile__user-wrap">
        <img
          class="my-profile__avatar"
          :src="getUserAvatar(getProfileData) || avatar"
          alt="avatar"
          width="50"
          height="50"
        >
        <div class="my-profile__user-information-wrap">
          <span class="my-profile__username">{{
            getProfileData.username || username
          }}</span>
          <span class="my-profile__name">{{
            getProfileData.name || name
          }}</span>
          <div class="d-flex justify-content-between mt-1">
            <span
              class="my-profile__followers"
            >{{ getProfileData.followers || followers }} followers</span>
            <span
              class="my-profile__following"
            >{{ getProfileData.following || following }} following</span>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="my-profile__rating btn btn-info"
        :disabled="myBalance <= 0"
        @click="getWithdraw"
      >
        <span class="text-uppercase fw-bold">Withdraw</span>
      </button>
      <div class="mt-3 fw-bold text-info">
        Available earnings:
        <span class="coin-icon" />{{ myBalance }}
      </div>
      <span class="my-profile__info">
        {{ getProfileData.bio || userinfo }}
      </span>
      <div class="my-profile__content-wrap">
        <TabGroup />
        <div class="my-profile__images d-flex flex-wrap mt-3">
          <div
            v-for="(postImage, index) in postsList"
            :key="index"
            class="my-profile__picture-wrap mt-3 position-relative"
          >
            <p
              class="
                text-light
                fw-light
                mb-0
                border-top
                d-flex
                justify-content-between
              "
            >
              {{ postImage.name }}
              <button
                class="btn zoom-in"
                :disabled="postImage.isBlurred&&isSavedPostsBlurred"
                @click="openLargeImage(postImage, index)"
              />
            </p>

            <div
              :id="`carouselExampleIndicators${index}`"
              class="carousel slide position-absolute w-100 h-100"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  v-for="(btn, i) in postImage.media"
                  :key="i"
                  type="button"
                  :data-bs-target="`#carouselExampleIndicators${index}`"
                  :data-bs-slide-to="i"
                  class="active"
                  aria-current="true"
                  :aria-label="`Slide ${i + 1}`"
                />
              </div>
              <div class="carousel-inner h-100">
                <div
                  v-for="(image, count) in postImage.media"
                  :key="count"
                  class="carousel-item h-100"
                  :class="{ active: count == 0 }"
                >
                  <img
                    v-if="image.type == `image`"
                    :src="
                      toggleBlurrImage(
                        postImage.isBlurred,
                        getPostImageUrl(postImage.id, image.id),
                        isSavedPostsBlurred
                      )
                    "
                    class="d-block w-100"
                    alt="image"
                  >

                  <video
                    v-else
                    controls
                    :poster="videoPoster"
                    width="250"
                    class="border p-1"
                  >
                    <source
                      :src="
                        toggleBlurrVideo(
                          postImage.isBlurred,
                          getPostVideoUrl(postImage.id, image.id),
                          isSavedPostsBlurred
                        )
                      "
                      type="video/webm"
                    >

                    <source
                      :src="
                        toggleBlurrVideo(
                          postImage.isBlurred,
                          getPostVideoUrl(postImage.id, image.id),
                          isSavedPostsBlurred
                        )
                      "
                      type="video/mp4"
                    >

                    Sorry, your browser doesn't support embedded videos.
                  </video>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                :data-bs-target="`#carouselExampleIndicators${index}`"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                :data-bs-target="`#carouselExampleIndicators${index}`"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="largeimage"
        class="large-view position-fixed top-50 start-50 translate-middle mt-3"
      >
        <button
          class="btn zoom-out"
          @click="closeLargeImage"
        />

        <LargeImage :my-props="{ largePostMedia: largePostMedia }" />
      </div>
    </div>
  </section>
</template>

<script>
import TabGroup from "../Partials/TabGroup";
import LargeImage from "./LargeImage.vue";
import { USER_DEFAULT_AVATAR, USER_DEFAULT_NAME } from "../../constants";

export default {
  name: "MyProfile",
  components: {
    TabGroup,
    LargeImage,
  },
  data() {
    return {
      renderedPosts: [],
      name: USER_DEFAULT_NAME,
      username: `My username`,
      avatar: USER_DEFAULT_AVATAR,
      followers: 0,
      following: 0,
      userinfo: `My information here...`,
      imagelarge: ``,
      largeimage: false,
      myBalance: 0,
      isModal: false,
      postPicType: ``,
      isMsgOpen: this.$store.state.is_message_box_open,
      isSavedPostsBlurred: false,
      videoPoster: ``,
      largePostMedia: [],
    };
  },
  computed: {
    postsList() {
      return this.renderedPosts;
    },
    getProfileData() {
      return this.$store.state.my_profile_info;
    },
    setProfileContent() {
      return this.$store.state.my_profile_content;
    },
  },
  watch: {
    setProfileContent(newVal, oldVal) {
      if (this.$store.state.my_profile_content.mycontent) {
        this.getMyPosts();
      } else if (this.$store.state.my_profile_content.purchased) {
        this.getMyPurchases();
      } else if (this.$store.state.my_profile_content.saved) {
        this.getMySaved();
      }
    },
  },

  mounted() {
    this.$store.dispatch("GET_PROFILE_INFO", `/${localStorage.getItem(`id`)}`);

    this.popupItem = this.$el;
    this.$store
      .dispatch("GET_MY_BALANCE", localStorage.getItem(`id`))
      .then(() => {
        this.myBalance = this.$store.state.my_balance.available.earnings;
      })
      .catch((err) => console.log(err));

    this.$store
      .dispatch(
        `GET_FOLLOWERS_FOLLOWINGS`,
        `?followee=${localStorage.getItem(`id`)}`
      )
      .then(() => {
        this.followers = this.$store.state.followers_following_array.total;
      })
      .then(() => {
        this.$store
          .dispatch(
            `GET_FOLLOWERS_FOLLOWINGS`,
            `?follower=${localStorage.getItem(`id`)}`
          )
          .then(() => {
            this.following = this.$store.state.followers_following_array.total;
          });
      });
    this.$store
      .dispatch("GET_ALL_POSTS", localStorage.getItem(`id`))
      .then(() => {
        this.renderedPosts = this.$store.state.all_posts;
      });
  },
  methods: {
    closeModal() {
      this.isModal = false;
    },
    openLargeImage(post, i) {
      this.largeimage = true;
      this.largePostMedia = post;
    },
    closeLargeImage() {
      this.largeimage = false;
      this.largePostMedia = [];
    },
    getWithdraw() {
      if (
        this.$store.state.my_balance.available.earnings &&
        this.myBalance < 10
      ) {
        this.isModal = true;
      } else {
        this.$router.push("/withdraw");
      }
    },
    getMyPosts() {
      this.$store.dispatch("GET_ALL_POSTS", localStorage.getItem(`id`));
      this.renderedPosts = this.$store.state.all_posts;
      this.isSavedPostsBlurred = false;
    },
    getMyPurchases() {
      this.isSavedPostsBlurred = false;
      let idsArr1 = [];

      this.$store
        .dispatch(`GET_EARNINGS`, `?accountId=${localStorage.getItem(`id`)}`)
        .then(() => {
          this.$store.state.earnings.data.map((item) => {
            if (item.accountId == localStorage.getItem(`id`)) {
              idsArr1.push(item.targetId);
            }
          });
          this.$store.dispatch(`GET_MY_PURCHASED_POSTS`, idsArr1).then(() => {
            this.renderedPosts = this.$store.state.my_purchased_posts.data;
          });
        });
    },
    getMySaved() {
      this.isSavedPostsBlurred = true;
      let idsArr2 = [];
      this.$store
        .dispatch(`GET_FAVORITES`, `/?accountId=${localStorage.getItem(`id`)}`)
        .then(() => {
          this.$store.state.favourites.data.map((item) => {
            idsArr2.push(item.targetId);
          });
          this.$store.dispatch(`GET_MY_SAVED_POSTS`, idsArr2).then(() => {
            this.renderedPosts = this.$store.state.my_saved_posts.data;
          });
        });
    },
    toggleBlurrImage(isBlurredRequired, imageUrl, mySavedPosts) {
      let path = ``;
      if (!isBlurredRequired || !mySavedPosts) {
        path = imageUrl;
      } else if (isBlurredRequired && mySavedPosts) {
        path = `https://catherineasquithgallery.com/uploads/posts/2021-02/1614426117_8-p-razmitii-temnii-fon-10.jpg`;
      }
      return path;
    },
    toggleBlurrVideo(isBlurredRequired, videoUrl, mySavedPosts) {
      let path = ``;
      if (!isBlurredRequired || !mySavedPosts) {
        path = videoUrl;
      } else if (isBlurredRequired && mySavedPosts) {
        path = ``;
        this.videoPoster = `https://catherineasquithgallery.com/uploads/posts/2021-02/1614426117_8-p-razmitii-temnii-fon-10.jpg`;
      }
      return path;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

img,
video,
iframe {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.my-profile {
  display: flex;
  flex-direction: column;
  background-color: $bgColorMain;
  font-size: $font-size-small;
  border: solid 1px transparent;
  min-height: calc(100vh - 52px);
  margin-top: 0px;
  padding: 10px;
  z-index: 1;
  @include media-breakpoint-up(md) {
    font-size: $font-size-medium;
  }
}

.my-profile:focus {
  outline: none !important;
  border-color: transparent;
  box-shadow: transparent;
}
[tabindex] {
  outline: none !important;
}
.my-profile__balance {
  width: 68px;
  height: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #1d1e1f;
  background-color: #5cc5de;
  border: none;
  border-radius: 15px;
  margin-left: 10px;

  @include media-breakpoint-up(md) {
    width: 90px;
    height: 35px;
    line-height: 33px;
  }
}
.my-profile__settings {
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  background-image: url($iconGear);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35px 35px;
}
.my-profile__user-wrap {
  display: flex;
  width: 100%;
  border: solid 1px transparent;
  margin: 100px auto 0px 0px;
}
.my-profile__user-information-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 1em;
  @include media-breakpoint-up(md) {
    width: 18em;
    margin-left: 2em;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 2.5em;
  }
}
.my-profile__avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: solid 1px transparent;

  @include media-breakpoint-up(md) {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    border: solid 1px transparent;
  }
  @include media-breakpoint-up(lg) {
    width: 200px;
    height: 200px;
  }
}
.my-profile__username {
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
  color: #ffffff;
}
.my-profile__name {
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  color: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.7;

  @include media-breakpoint-up(md) {
    margin-top: 2em;
  }
}
.my-profile__followers,
.my-profile__following {
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  color: #ffffff;
  @include media-breakpoint-up(md) {
    margin-top: 2em;
  }
}

.my-profile__rating {
  width: 12em;
  height: 3em;
  border-radius: 41px;
  margin-top: 30px;
}
.hearts-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  color: #f9f9f9;
  padding: 0px 10px 0px 10px;
}
.font-weight-light {
  font-weight: 300;
  margin-top: 5px;
}
.my-profile__rating-heart {
  display: block;
  width: 15px;
  height: 15px;
  background-image: url($iconRatingHeart);
  background-repeat: no-repeat;
  background-position: center;
  border: solid 1px transparent;
}
.my-profile__rating-heart-empty {
  display: block;
  width: 15px;
  height: 15px;
  background-image: url($iconRatingHeartEmpty);
  background-repeat: no-repeat;
  background-position: center;
  border: solid 1px transparent;
}
.my-profile__rating-number {
  display: none;
  border: solid 1px transparent;
}
.my-profile__info {
  display: block;
  font-style: normal;
  font-weight: normal;
  line-height: 32px;
  color: #ffffff;
  margin-top: 30px;
}
.my-profile__social-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: solid 1px transparent;
  margin-top: 30px;
}
.my-profile__social-fb {
  display: block;
  width: 25px;
  height: 25px;
  background-image: url($iconFb);
  background-repeat: no-repeat;
  background-position: center;
}
.my-profile__social-instagram {
  display: block;
  width: 25px;
  height: 25px;
  background-image: url($iconInst);
  background-repeat: no-repeat;
  background-position: center;
}
.my-profile__social-twitter {
  display: block;
  width: 25px;
  height: 25px;
  background-image: url($iconTwitter);
  background-repeat: no-repeat;
  background-position: center;
}
.my-profile__social-sf {
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  color: #ffffff;
}
.my-profile__content-wrap {
  width: 100%;
  background-color: transparent;
  border: solid 1px transparent;
  margin-top: 20px;
}
.my-profile__images {
  display: block;
  border: solid 1px transparent;
  margin: 0px auto 50px auto;

  @include media-breakpoint-up(md) {
    margin: 0px auto 50px auto;
    gap: 30px;
  }
  @include media-breakpoint-up(lg) {
    margin: 0px auto 50px auto;
    gap: 45px;
  }
  @include media-breakpoint-up(xl) {
    margin: 0px auto 50px auto;
    gap: 53px;
  }
}
.my-profile__images::-webkit-scrollbar {
  width: 0;
}
.my-profile__content-picture {
  width: 126px;
  height: 126px;
  border: solid 1px #1d1d1d;
  border-radius: 5px;
  margin: 5px;

  @include media-breakpoint-up(md) {
    width: 156px;
    height: 156px;
    border-radius: 5px;
    margin: 5px;
  }
}
.my-profile__picture-wrap {
  width: 100%;
  padding-bottom: 100%;
  border: solid 1px transparent;
  position: relative;
  cursor: pointer;
  @include media-breakpoint-up(md) {
    width: 30%;
    border: solid 1px transparent;
    padding-bottom: 30%;
  }
}
.large-view {
  width: 90%;
  background-color: black;
  z-index: 2;
  @include media-breakpoint-up(md) {
    height: 500px;
  }
  @include media-breakpoint-up(lg) {
    height: 600px;
  }
  @include media-breakpoint-up(xl) {
    height: 600px;
  }
}
.large-image {
  width: 100%;
  height: 100%;
}
.post-video {
  width: 100%;
  height: 100%;
}
.video-icon {
  width: 5em;
  height: 5em;
  background-image: url($iconVideo);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5em 5em;
}
.withdraw-modal {
  width: 100%;
  margin-top: 16em;
  border: solid 1px transparent;
  z-index: 1;
  @include media-breakpoint-up(md) {
    width: 30em;
  }
  @include media-breakpoint-up(lg) {
    width: 35em;
  }
  @include media-breakpoint-up(xl) {
    width: 40em;
  }
  @include media-breakpoint-up(xxl) {
    width: 42em;
  }
}
.coin-icon {
  display: inline-block;
  width: 23px;
  height: 23px;
  border-radius: 50px;
  background-color: white;
  background-image: url("../../assets/img/icon-b-coin.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 23px 23px;
  margin: 0px 5px -3px 2px;
}
.zoom-in,
.zoom-out {
  width: 40px;
  height: 40px;
  background-image: url("../../assets/img/icon-zoom-in.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 25px;
}
.zoom-out {
  background-image: url("../../assets/img/icon-zoom-out.svg");
}
</style>
