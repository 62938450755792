import Vue from 'vue'
import App from './App.vue'
import {
  URL_POSTS,
  URL_AVATARS,
  URL_IMAGE_NOT_FOUND,
  AVATAR_NOT_FOUND,
  IMAGE_TYPE
} from './constants.js'
import router from './router'
import {
  store
} from './store';
import Axios from 'axios';

const dayjs = require("dayjs");

Vue.prototype.$http = Axios;
const token = store.state.token;

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    roundAmount(num) {
      return Math.round(num * 100) / 100;
    },
    getDate(date) {
      return dayjs(date).format("MMMM D, YYYY h:mm A");
    },
    getDateFromNow(date) {
      let relativeTime = require("dayjs/plugin/relativeTime");
      dayjs.extend(relativeTime);
      return dayjs(date).fromNow();
    },
    getPostUrl(post) {
      let link = ``;
      if (!post.media || !post.media[0].id) {
        link = URL_IMAGE_NOT_FOUND;
      } else {
        link = `${URL_POSTS}/${post.id}/main/${post.media[0].id}${IMAGE_TYPE}`;
      }
      return link;
    },
    getReportedPostUrl(post) {
      let link = ``;
      if (!post.media || !post.media[0].id) {
        link = URL_IMAGE_NOT_FOUND;
      } else {
        link = `${URL_POSTS}/${post.targetId}/main/${post.media[0].id}${IMAGE_TYPE}`;
      }
      return link;
    },
    getPostImageUrl(arg1, arg2) {
      return `${URL_POSTS}/${arg1}/main/${arg2}.jpeg`;
    },
    getPostVideoUrl(arg1, arg2) {
      return `${URL_POSTS}/${arg1}/main/${arg2}.mp4`;
    },
    getAvatarUrl(avatarId) {
      if (!avatarId) {
        return URL_IMAGE_NOT_FOUND
      }
      return `${URL_AVATARS}/${avatarId}${IMAGE_TYPE}`
    },
    getUserAvatar(user) {
      if (!user.avatarUpdatedTimestamp) {
        return AVATAR_NOT_FOUND
      }
      return `${URL_AVATARS}/${user.id}${IMAGE_TYPE}`
    },
    getImageType(post) {
      if (!post.media || !post.media[0].type) {
        return `image`
      }
      return post.media[0].type
    },
    randomInRange() {
      return Math.floor(Math.random() * (9999 - 999 + 1) + 999);
    },
    isBlurred(post) {
      if (!post.isBlurred) {
        return false;
      }
      return true;
    }
  }
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')