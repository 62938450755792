<template>
  <div>
    <AdminGeneralTopBar />
    <div class="admin__page container mt-5">
      <section
        class="admin-posts-view position-relative"
      >
        <div class="users-wrap text-break">
          <form
            class="users-filter"
            @submit.prevent="searchUser"
          >
            <button
              type="button"
              class="btn w-100 h-50 btn-dark"
              @click="clearSearch"
            >
              All users
            </button>

            <label
              class="search-label"
              for="user-search"
            >
              <input
                id="user-search"
                v-model="search"
                class="text-white"
                type="text"
                placeholder="Search..."
              >
            </label>
          </form>

          <div
            v-for="(user, index) in usersList"
            v-show="showUser == user.id || !hideUsers"
            :key="user.id"
            class="user-item justify-content-start"
            :class="{ 'user-active': activeUser == user.id }"
            @click="showUserPosts(user, index)"
          >
            <img
              class="img rounded-circle user-avatar"
              :src="getUserAvatar(user)"
              alt="avatar"
              onerror="this.onerror=null; this.src='../../images/default-avatar.jpeg'"
              width="40"
              height="40"
            >
            <span class="text-white text-truncate ms-3">{{ user.username || username }}</span>
          </div>
        </div>
        <div
          class="
            posts-wrap
            d-flex
            flex-wrap
            justify-content-start
            pt-3
            position-relative
            border-top
          "
        >
          <div class="toggle-posts-btns">
            <div
              class="btn-group"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                id="btnradio1"
                type="radio"
                class="btn-check"
                name="btnradio"
                autocomplete="off"
                checked
              >
              <label
                class="btn btn-outline-info post-btn"
                for="btnradio1"
                @click="showPosts"
              >Posts</label>

              <input
                id="btnradio0"
                type="radio"
                class="btn-check"
                name="btnradio"
                autocomplete="off"
              >
              <label
                class="btn btn-outline-info post-btn"
                for="btnradio0"
                @click="showReportedPosts"
              >Reported posts</label>

              <input
                id="btnradio2"
                type="radio"
                class="btn-check"
                name="btnradio"
                autocomplete="off"
              >
              <label
                class="btn btn-outline-info post-btn"
                for="btnradio2"
                @click="showBlockedPosts"
              >Blocked posts</label>

              <input
                id="btnradio3"
                type="radio"
                class="btn-check"
                name="btnradio"
                autocomplete="off"
              >
              <label
                class="btn btn-outline-info post-btn"
                for="btnradio3"
                @click="showVerifiedPosts"
              >Verified by Admin</label>
            </div>
          </div>
          <div
            v-for="(post, index) in postsList"
            :key="index"
            class="post-item"
          >
            <div class="post-wrap position-relative">
              <div class="user-info d-flex align-items-center">
                <img
                  class="img rounded-circle user-avatar"
                  :src="getAvatarUrl(post.accountId) || useravatar"
                  alt="avatar"
                  width="40"
                  height="40"
                >
                <span class="text-white text-truncate post-username">{{
                  usernamesObj.get(post.accountId) || username
                }}</span>
              </div>
              <div
                class="pic-wrap"
                @click="openLargeImage(post, index)"
              >
                <img
                  v-if="post.media[0].type == `image`"
                  class="post-pic"
                  :src="getPostUrl(post) || useravatar"
                  alt="pic"
                >
                <img
                  v-else
                  class="post-pic border border-dark border-3 p-5"
                  src="https://cdn-icons-png.flaticon.com/512/860/860780.png"
                  alt="pic"
                >
              </div>
              <div class="post-info d-flex flex-column">
                <span
                  class="text-white fw-bold"
                  :class="{ 'text-truncate': isTextExpanded !== post.id }"
                  @click="expandText(post, index)"
                >{{ post.name || `New post` }}</span>

                <span
                  class="post-info__info text-white fst-italic"
                  :class="{ 'text-truncate': isTextExpanded !== post.id }"
                  @click="expandText(post, index)"
                >{{ post.content || `Description...` }}</span>

                <div class="likes-wrap d-flex mt-1">
                  <span
                    class="text-white fw-bold"
                  >{{ likesArray[post.id] || 0 }} likes</span>
                  <span
                    class="text-white fw-bold"
                  >{{ dislikesArray[post.id] || 0 }} dislikes</span>
                </div>
              </div>
              <div class="post-date text-white">
                {{ getDate(post.created) }}
              </div>
              <div class="d-flex flex-column justify-content-between mt-1">
                <div class="post-status-wrap d-flex flex-column">
                  <span
                    class="text-danger fw-bold"
                  >{{ post.status || `` }}
                    <span
                      v-if="postStatus == 'REPORTED'"
                    >: {{ userReportsQtty[post.id] || `` }}</span></span>
                  <span
                    class="post-status-msg text-white"
                    :class="{ 'text-truncate': isTextExpanded !== post.id }"
                    @click="expandText(post, index)"
                  >{{ post.statusMessage || `` }}</span>
                  <span class="text-white post-date m-0">{{
                    getDate(post.updated)
                  }}</span>
                </div>

                <div class="d-flex justify-content-end">
                  <button
                    v-if="post.status !== `BLOCKED`"
                    type="button"
                    class="btn btn-info block-btn"
                    @click="openBlockPopup(post, index)"
                  >
                    Block
                  </button>

                  <button
                    v-if="post.status == `BLOCKED` || showApproveButton"
                    type="button"
                    class="btn btn-info block-btn ms-3"
                    @click="approvePost(post, index)"
                  >
                    Approve
                  </button>
                </div>
              </div>

              <form
                v-if="showpopup == post.id"
                class="
                  position-absolute
                  top-0
                  start-0
                  bg-dark
                  pt-1
                  pb-3
                  px-1
                  d-flex
                  flex-column
                  w-100
                  border
                "
                @submit.prevent="submitReson(post, index)"
              >
                <label
                  for="reason-input"
                  class="text-white mt-1"
                >Reason to block:
                </label>
                <textarea
                  id="reason-input"
                  :ref="post.id"
                  v-model="reason"
                  class="w-100 mt-1"
                  name="story"
                  rows="5"
                  cols="23"
                  maxlength="100"
                  required
                />
                <button
                  class="btn btn-info btn-sm mt-3"
                  type="submit"
                >
                  Save changes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary btn-sm mt-1"
                  @click="closeBlockPopup(post, index)"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
        <div class="pagination-wrap position-absolute bottom-0 end-0">
          <PaginationNavigation />
        </div>
        <div
          v-if="userNotFound"
          class="user-not-found text-white"
        >
          <p>User is not found</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import PaginationNavigation from "../../Partials/PaginationNavigation.vue";
import { USER_DEFAULT_AVATAR } from "../../../constants.js";
import AdminGeneralTopBar from "../General/TopBar";

export default {
  name: "AdminPosts",
  components: {
    PaginationNavigation,
    AdminGeneralTopBar,
  },
  data() {
    return {
      search: ``,
      userid: ``,
      useravatar: USER_DEFAULT_AVATAR,
      username: "Username",
      showpopup: false,
      getBlockedPosts: false,
      showUser: true,
      hideUsers: false,
      activeUser: false,
      reason: ``,
      postStatus: `POSTED`,
      userNotFound: false,
      showApproveButton: false,
      isTextExpanded: false,
      limit: this.$store.state.pagination_object.limit || 50,
      skip: 0,
      usersAvatar: {},
      usernamesObj: {},
      likesArray: {},
      dislikesArray: {},
      userReportsQtty: {},
    };
  },
  computed: {
    usersList() {
      return this.$store.state.users.data;
    },

    postsList() {
      return this.$store.state.posts.data;
    },
    getPaginationData() {
      return this.$store.state.pagination_object;
    },
  },
  watch: {
    getPaginationData(newVal, oldVal) {
      this.limit = newVal.limit;
      this.skip = newVal.skip;
      this.$store
        .dispatch(
          `GET_POSTS`,
          `?${this.userid}&status=${this.postStatus}&$limit=${this.limit}&$skip=${this.skip}`
        )
        .then(() => {
          let ids = [];
          this.$store.state.posts.data.map((item) => {
            ids.push(item.id);
          });
          this.getLikesQtty(ids);
          this.getDisikesQtty(ids);
          this.getReports({ type: `POST`, storeArr: ids });
        });
    },
    postsList(newVal, oldVal) {
      this.$store.commit(
        `SET_TOTAL_ITEMS_PAGINATION`,
        this.$store.state.posts.total
      );
    },
  },

  mounted() {
    this.usernamesObj = new Map();
    this.usersAvatar = new Map();

    this.$store.dispatch("GET_USER", `?`).then(() => {
      if (this.$store.state.users.data) {
        this.$store.state.users.data.map((user) => {
          this.usernamesObj.set(user.id, user.username);
          this.usersAvatar.set(user.id, user.avatar);
        });
      }
    });
    this.$store
      .dispatch(
        `GET_POSTS`,
        `?status=${this.postStatus}&$limit=${this.limit}&$skip=${this.skip}`
      )
      .then(() => {
        let ids = [];
        this.$store.state.posts.data.map((item) => {
          ids.push(item.id);
        });

        this.getLikesQtty(ids);
        this.getDisikesQtty(ids);

        this.getReports({ type: `POST`, storeArr: ids });
      });
    this.popupItem = this.$el;
  },

  methods: {
    /// GET REPORTS
    getReports(arg) {
      let payload = {
        ids: arg.storeArr,
        subject: arg.type,
      };
      this.$store.dispatch(`GET_REPORTS`, payload).then(() => {
        this.userReportsQtty = this.$store.state.reports;
      });
    },
    /// GET REACTIONS AMOUNT
    getLikesQtty(arg) {
      this.$store.dispatch(`GET_LIKES_AMOUNT`, arg).then(() => {
        this.likesArray = this.$store.state.likes_amount;
      });
    },
    getDisikesQtty(arg) {
      this.$store.dispatch(`GET_DISLIKES_AMOUNT`, arg).then(() => {
        this.dislikesArray = this.$store.state.dislikes_amount;
      });
    },
    // EXPAND TEXT

    expandText(post, i) {
      if (!this.isTextExpanded) {
        this.isTextExpanded = post.id;
      } else {
        this.isTextExpanded = false;
      }
    },

    getPostType(post, i) {
      if (post.icon) {
        return post.icon.split(".").pop();
      }
      return ``;
    },

    // SHOW SELECTED USER'S POSTS

    showUserPosts(user, index) {
      this.activeUser = user.id;
      this.showUser = user.id;
      this.hideUsers = true;
      this.userid = `accountId=${user.id}`;
      this.useravatar = user.avatar;
      this.username = user.username;
      this.$store.dispatch(
        `GET_POSTS`,
        `?${this.userid}&status=${this.postStatus}&$limit=${this.limit}&$skip=${this.skip}`
      );
    },

    // OPEN AND CLOSE BLOCK POPUP

    openBlockPopup(post, i) {
      this.showpopup = post.id;
      this.$nextTick(() => {
        this.$refs[post.id][0].focus();
      });
    },
    closeBlockPopup(p, i) {
      this.showpopup = false;
      this.reason = ``;
    },

    // SUBMIT REASON AND BLOCK POST

    submitReson(post, i) {
      const objToBlock = {
        id: post.id,
        status: {
          status: `BLOCKED`,
          statusMessage: this.reason,
        },
      };
      this.$store
        .dispatch(`BLOCK_POST`, objToBlock)
        .then(() => {
          this.$store.dispatch(
            `GET_POSTS`,
            `?${this.userid}&status=${this.postStatus}&$limit=${this.limit}&$skip=${this.skip}`
          );
          this.showpopup = false;
          this.reason = ``;
        })
        .then(() => {
          this.$store.dispatch(`SEND_MESSAGE`, {
            accountId: localStorage.getItem(`id`),
            toAccountId: post.accountId,
            data: [
              {
                content: `Your post has been removed for violating our community guidelines.`,
                id: localStorage.getItem(`id`),
                type: `text`,
                postInfo: post,
              },
            ],
          });
        });
    },

    // UNBLOCK AND VERIFY POSTS

    approvePost(post, i) {
      const objToVerify = {
        id: post.id,
        status: {
          status: `VERIFIED`,
          statusMessage: `Verified by Admin`,
        },
      };

      this.$store.dispatch(`BLOCK_POST`, objToVerify).then(() => {
        this.$store.dispatch(
          `GET_POSTS`,
          `?${this.userid}&status=${this.postStatus}&$limit=${this.limit}&$skip=${this.skip}`
        );
      });
    },

    // GO TO POST PAGE

    openLargeImage(post, i) {
      this.$router.push(`/post/${post.id}`);
    },


    // SEARCH USER
    searchUser() {
      console.log('Searching user' + this.search);
      let total = 0;
      this.$store.dispatch(`FIND_USERS`, `?q=${this.search}`).then(() =>
      {
        // total records
        total = this.$store.state.users.total;
        this.userNotFound = total === 0;

        this.userid = `accountId=${this.$store.state.users.data[0].id}`;

        this.useravatar = this.$store.state.users.data[0].avatar;
        this.username = this.$store.state.users.data[0].username;

        this.activeUser = this.$store.state.users.data[0].id;
        this.showUser = this.$store.state.users.data[0].id;

        // if we found only one record display his posts
        if (total === 1)
        {
          this.$store.dispatch(
            'GET_POSTS',
            `?${this.userid}&status=${this.postStatus}&$limit=${this.limit}&$skip=${this.skip}`
          );
        }
      });
    },

    clearSearch() {
      this.userNotFound = false;
      this.activeUser = false;
      this.showUser = false;
      this.hideUsers = false;
      this.search = ``;
      this.userid = ``;
      this.useravatar = USER_DEFAULT_AVATAR;
      this.username = `Username`;
      this.$store.dispatch("GET_USER", `?`);
      this.$store.dispatch(
        `GET_POSTS`,
        `?${this.userid}&status=${this.postStatus}&$limit=${this.limit}&$skip=${this.skip}`
      );
    },

    // TOGGLE MENU BUTTONS FUNCTIONALITY

    showPosts() {
      this.showApproveButton = false;
      this.postStatus = `POSTED`;
      this.$store
        .dispatch(`GET_POSTS`, `?${this.userid}&status=${this.postStatus}`)
        .then(() => {
          let ids = [];
          this.$store.state.posts.data.map((item) => {
            ids.push(item.id);
          });
          this.getLikesQtty(ids);
          this.getDisikesQtty(ids);
        });
    },
    showBlockedPosts() {
      this.showApproveButton = false;
      this.postStatus = `BLOCKED`;
      this.$store
        .dispatch(`GET_POSTS`, `?${this.userid}&status=${this.postStatus}`)
        .then(() => {
          let ids = [];
          this.$store.state.posts.data.map((item) => {
            ids.push(item.id);
          });
          this.getLikesQtty(ids);
          this.getDisikesQtty(ids);
        });
    },
    showVerifiedPosts() {
      this.showApproveButton = false;
      this.postStatus = `VERIFIED`;
      this.$store
        .dispatch(`GET_POSTS`, `?${this.userid}&status=${this.postStatus}`)
        .then(() => {
          let ids = [];
          this.$store.state.posts.data.map((item) => {
            ids.push(item.id);
          });
          this.getLikesQtty(ids);
          this.getDisikesQtty(ids);
        });
    },
    showReportedPosts() {
      this.showApproveButton = true;
      this.postStatus = `REPORTED`;
      this.$store
        .dispatch(`GET_POSTS`, `?${this.userid}&status=${this.postStatus}`)
        .then(() => {
          let ids = [];
          this.$store.state.posts.data.map((item) => {
            ids.push(item.id);
          });
          this.getLikesQtty(ids);
          this.getDisikesQtty(ids);
          this.getReports({ type: `POST`, storeArr: ids });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

img,
video {
  display: block;
  object-fit: cover;
}

.admin-posts-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 7em;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.admin-posts-view:focus {
  outline: none !important;
  border-color: transparent;
  box-shadow: transparent;
}
[tabindex] {
  outline: none !important;
}
.users-wrap {
  display: flex;
  overflow-x: auto;
  width: 247px;
  min-height: 50px;

  @include media-breakpoint-up(md) {
    flex-direction: column;
    justify-content: flex-start;
    overflow: initial;
    width: auto;
    min-height: 300px;
    margin-top: -0.9em;
    padding-right: 0px;
  }
  @include media-breakpoint-up(lg) {
    min-width: 200px;
    min-height: 330px;
    margin-top: 1em;
  }
}
.users-filter {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
    width: 160px;
    height: 75px;
  }
  @include media-breakpoint-up(lg) {
    width: 170px;
  }
  @include media-breakpoint-up(xl) {
    width: 170px;
  }
}
.search-label {
  border: solid 1px rgb(190, 180, 180);
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    background-image: url("../../../assets/img/icon-search.svg");
    background-repeat: no-repeat;
    background-position: 130px 0px;
    background-size: 25px 25px;
    opacity: 0.6;
    margin-top: 6px;
  }
  @include media-breakpoint-up(lg) {
    background-position: 140px 0px;
  }
  @include media-breakpoint-up(xl) {
  }
}
#user-search {
  background-color: transparent;
  border: none;
  @include media-breakpoint-up(md) {
    width: 100%;
  }
  @include media-breakpoint-up(lg) {
  }
  @include media-breakpoint-up(xl) {
  }
}
.user-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 245px;
  width: 245px;
  margin-right: 15px;
  padding: 5px;
  cursor: pointer;
  background-color: rgb(51, 51, 53);
  opacity: 0.9;

  @include media-breakpoint-up(md) {
    width: 170px;
    min-width: 100px;
    min-height: 60px;
    margin-top: 5px;
    margin-right: 0px;
    padding-right: 5px;
    background-color: transparent;
  }
  @include media-breakpoint-up(lg) {
    width: 180px;
    min-height: 70px;
    margin-top: 0px;
  }
  @include media-breakpoint-up(xl) {
    width: 200px;
  }
}
.user-item span {
  width: 170px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.users-wrap::-webkit-scrollbar {
  display: none;
}
.post-item {
  width: 247px;
  background-color: rgb(51, 51, 53);
  border: solid 1px #b7cee5;
  opacity: 0.9;
  margin: 0 auto;
  padding: 5px;

  @include media-breakpoint-up(md) {
    width: 240px;
    margin: 0px;
  }
  @include media-breakpoint-up(lg) {
    width: 230px;
  }
}
.posts-wrap {
  margin-top: 6em;
  gap: 5px;

  @include media-breakpoint-up(md) {
    width: 550px;
    margin-top: 5em;
    margin-left: 15px;
  }
  @include media-breakpoint-up(lg) {
    width: 814px;
    margin-top: 2em;

    margin-left: 5px;
  }
  @include media-breakpoint-up(xl) {
    width: 814px;
    margin-left: 99px;
  }
  @include media-breakpoint-up(xxl) {
    width: 1078px;
    margin-left: 94px;
    gap: 15px;
  }
}
.post-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pic-wrap {
  width: 100%;
  height: 0;
  padding: 0 0 100%;
  position: relative;
  cursor: pointer;
}
.post-pic {
  width: 100%;
  height: 100%;
  position: absolute;
}
.user-info {
  width: 100%;
  min-height: 50px;

  @include media-breakpoint-up(md) {
    width: 100%;
    min-height: 70px;
  }
}
.post-info {
  width: 100%;
  font-size: $font-size-mobile;
  margin-top: 1em;
  height: 100%;

  @include media-breakpoint-up(md) {
    width: 100%;
    font-size: $font-size-small;
    margin-top: 0.5em;
  }
}
.post-info__info {
  display: block;
  min-height: 4em;
  word-break: break-all;
  cursor: pointer;
}
.hidden {
  display: none;
}
.blocked {
  background-color: red;
}
.btn-info:focus {
  color: #000;
  border-color: transparent;
  box-shadow: none;
}
.btn-info:active {
  color: #000;
  border-color: transparent;
  box-shadow: none;
}
.user-active {
  border-right: solid 1px grey;
}
.block-btn {
  width: 6em;
}
.large-view {
  width: 100%;
  height: 0;
  padding: 0 0 100%;
  background-color: black;
  @include media-breakpoint-up(md) {
    width: 500px;
    height: 500px;
    margin-top: 1.5em;
    padding: 0;
  }
  @include media-breakpoint-up(lg) {
    width: 60em;
    height: 38em;
  }
  @include media-breakpoint-up(xl) {
    width: 70em;
  }
  @include media-breakpoint-up(xl) {
    width: 80em;
  }
}
.large-image {
  width: 100%;
  height: 100%;
}
.post-username {
  margin-left: 0.5em;
}
.post-date {
  font-size: $font-size-mobile;
  font-weight: 300;
  font-style: italic;
  margin-top: 0.1em;
  opacity: 0.5;
  border: solid 1px transparent;
  @include media-breakpoint-up(md) {
    margin-top: 0.5em;
  }
}
.post-video {
  width: 100%;
  height: 100%;
  outline: solid 3px red;
}
.video-icon {
  width: 5em;
  height: 5em;
  background-image: url($iconVideo);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5em 5em;
}
.post-btn {
  font-size: $font-size-mobile;
  @include media-breakpoint-up(md) {
    width: 9em;
    font-size: $font-size-small;
  }
  @include media-breakpoint-up(lg) {
    width: 10em;
    font-size: $font-size-medium;
  }
}
.toggle-posts-btns {
  position: absolute;
  left: 50% !important;
  transform: translate(-50%, -160%) !important;
  @include media-breakpoint-up(md) {
    transform: translate(-50%, -181%) !important;
  }
  @include media-breakpoint-up(lg) {
    transform: translate(-50%, -165%) !important;
  }
}
.pagination-wrap {
  width: 100%;
  min-height: 4em;
}
.droplist-wrap {
  width: 3em;
  height: 3em;
  opacity: 0.5;
}
.post-status-wrap {
  min-height: 6em;
  font-size: $font-size-mobile;
  text-align: end;
  @include media-breakpoint-up(md) {
    font-size: $font-size-small;
  }
}
.likes-wrap span:nth-child(2) {
  margin-left: 3em;
}
.user-not-found {
  position: absolute;
  transform: translate(0%, 95%) !important;
  background-color: $bgColorMain;
  width: 10em;
  height: 5em;
  @include media-breakpoint-up(lg) {
    transform: translate(0%, 125%) !important;
    width: 12em;
    height: 5em;
  }
}
.post-status-msg {
  min-height: 1em;
}
</style>
