import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';

import createPersistedState from "vuex-persistedstate";

const BASE_URL = process.env.VUE_APP_URL || `api.brij.im`;

import SecureLS from "secure-ls";
let ls = new SecureLS({
    encodingType: "aes",
    isCompression: false,
    encryptionSecret: "Your key"
});

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        users: [],
        search_user:[],
        top_creators: [],
        posts: [],
        all_users_info: [],
        all_posts: [],
        payment_requests: [],
        payment_approve: [],
        user_blocking_status: [],
        modified_user: [],
        status: '',
        token: '',
        user: [],
        new_password: [],
        my_profile_content: {
            mycontent: true,
            purchased: false,
            saved: false
        },
        my_profile_info: {},
        is_admin: ``,
        earnings: [],
        earnings: [],
        my_balance: [],
        usd_amount: [],
        user_info: [],
        is_message_box_open: false,
        my_page_content: [],
        notifications: [],
        unread_notifications: [],
        feed_posts: [],
        pagination_object: {
            limit: 50,
            skip: 0
        },
        reactions_array: [],
        admin_content: {},
        total_items_pagination: 0,
        followers_following_array: [],
        favourites: [],
        reports: [],
        user_reports: [],
        reports_array: [],
        likes_amount: [],
        dislikes_amount: [],
        rating: [],
        my_purchased_posts: [],
        my_saved_posts: [],
        comments: [],
        messages: []
    },
    plugins: [
        createPersistedState({
            paths: ['is_admin', 'token'],
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) =>
                    ls.set(key, value, {
                        expires: 7
                    }),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],
    mutations: {
        SET_ALL_USERS_INFO: (state, payload) => {
            state.all_users_info = payload;
        },
        SET_USER: (state, payload) => {
            state.users = payload;
        },
        SET_SEARCH_USER: (state, payload) => {
            state.search_user = payload;
        },
        // TOP CREATORS
        SET_TOP_CREATORS: (state, payload) =>{
          state.top_creators = payload;
        },
        SET_POSTS: (state, payload) => {
            state.posts = payload;
        },
        ADD_POST: (state, payload) => {
            state.posts.push(payload);
        },
        // TOP CREATORS
        ADD_TOP_CREATOR: (state, payload) => {
            state.top_creators.push(payload);
        },
        SET_PAYMENT_REQUESTS: (state, payload) => {
            state.payment_requests = payload;
        },
        SET_PAYMENT_APPROVE: (state, payload) => {
            state.payment_approve = payload;
        },
        SET_MODIFIED_USER: (state, payload) => {
            state.modified_user = payload;
        },
        ADD_BLOCKED_USERS: (state, payload) => {
            state.user_blocking_status.push(payload);
        },
        SET_ALL_POSTS: (state, payload) => {
            state.all_posts = payload;
        },
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token, payload) {
            state.status = 'success'
            state.token = token
            state.user.push(payload);
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
        SET_NEW_PASSWORD(state, payload) {
            state.emails = payload;
        },
        SET_PROFILE_CONTENT(state, payload) {
            state.my_profile_content = payload;
        },
        SET_PROFILE_INFO(state, payload) {
            state.my_profile_info = payload;
        },
        SET_ADMIN(state, payload) {
            state.is_admin = payload;
        },
        SET_EARNINGS(state, payload) {
            state.earnings.push(payload);
        },
        SET_EARNINGS(state, payload) {
            state.earnings = payload;
        },
        SET_MY_BALANCE(state, payload) {
            state.my_balance = payload;
        },
        SET_USD_AMOUNT(state, payload) {
            state.usd_amount = payload;
        },
        SET_USER_INFO(state, payload) {
            state.user_info.push(payload);
        },
        SET_MESSAGE_BOX(state, payload) {
            state.is_message_box_open = payload;
        },
        SET_PAGE_CONTENT(state, payload) {
            state.my_page_content = payload;
        },
        SET_NOTIFICATIONS(state, payload) {
            state.notifications = payload;
        },
        SET_UNREAD_NOTIFICATIONS(state, payload) {
            state.unread_notifications = payload;
        },
        SET_FEED(state, payload) {
            state.feed_posts = payload;
        },
        SET_PAGINATION(state, payload) {
            state.pagination_object = payload;
        },
        SET_REACTIONS_ARRAY(state, payload) {
            state.reactions_array = payload;
        },
        SET_ADMIN_CONTENT(state, payload) {
            state.admin_content = payload;
        },
        SET_TOTAL_ITEMS_PAGINATION(state, payload) {
            state.total_items_pagination = payload;
        },
        SET_FOLLOWERS_FOLLOWINGS(state, payload) {
            state.followers_following_array = payload;
        },
        SET_FAVORITES(state, payload) {
            state.favourites = payload;
        },
        SET_REPORTS(state, payload) {
            state.reports = payload;
        },
        SET_REPORTS_ARRAY(state, payload) {
            state.reports_array = payload;
        },
        SET_USER_REPORTS(state, payload) {
            state.user_reports = payload;
        },
        SET_LIKES_AMOUNT(state, payload) {
            state.likes_amount = payload;
        },
        SET_DISLIKES_AMOUNT(state, payload) {
            state.dislikes_amount = payload;
        },
        SET_RATING(state, payload) {
            state.rating = payload;
        },
        SET_MY_PURCHASED_POSTS(state, payload) {
            state.my_purchased_posts = payload;
        },
        SET_MY_SAVED_POSTS(state, payload) {
            state.my_saved_posts = payload;
        },
        SET_COMMENTS(state, payload) {
            state.comments = payload;
        },
        SET_MESSAGES(state, payload) {
            state.messages = payload;
        }
    },
    actions: {
        GET_ALL_USERS_INFO: async (context, payload) => {
            let queryArr = [];
            let usersInfoArray = new Map();
            console.log('Get all users data');

            payload.map((id) => {
                queryArr.push(`id[$in][]=${id}&`);
            });
            let query = queryArr.join(``);

            let arr2 = await Axios.get(`https://${BASE_URL}/account-details?${query}`);

            arr2.data.data.map((user) => {
                usersInfoArray.set(user.id, user);
            })

            context.commit(`SET_ALL_USERS_INFO`, usersInfoArray);
        },
        GET_USER: async (context, payload) => {
            console.log('Payload', payload);
            const url = `https://${BASE_URL}/accounts${payload}&$sort[updated]:1`;
            console.log('URL', url);
            let {
                data
            } = await Axios.get(url).catch(e => console.log(e));
            console.log('Data', data);
            context.commit('SET_USER', data);
        },
        // TOP CREATORS
        GET_TOP_CREATORS: async (context, payload) => {
            console.log('ANDREI - get top creators');

            // get all the top creator objects
            let {data} = await Axios.get(`https://${BASE_URL}/top-creators`).catch(
              e => console.log(e)
            );
            console.log('TOP CREATORS', data.data);

            let topCreators = [];

            if (data.total !== 0) {
              // build list of filters to get top creators accounts based on ids
              let topIds = data.data.map((c)=>`id[$in][]=${c.creatorId}`).join("&");
              console.log('TOP CREATOR IDS', topIds);

              // grab full top creators accounts
              let accounts = await Axios.get(
                  `https://${BASE_URL}/accounts?${topIds}`).catch(e => console.log(e));

              topCreators = accounts.data.data;

              for (var i = 0; i < topCreators.length; i++) {
                for (var j = 0; j < data.data.length; j++) {
                  if (topCreators[i].id === data.data[j].creatorId) {
                    topCreators[i].created = data.data[j].created;
                    topCreators[i].updated = data.data[j].updated;
                    break;
                  }
                }
              }
            }

            console.log('Final top creators', topCreators);

            topCreators.sort(function(a,b) {
              return new Date(b.created) - new Date(a.created);
            });

            // save top creators
            context.commit('SET_TOP_CREATORS', topCreators);
        },
        SAVE_TOP_CREATORS: async (context, payload) => {

          console.log('ANDREI - SAVE_TOP_CREATORS', payload);

          // get account for the new top creator
          let newTop = await Axios.get(
            `https://${BASE_URL}/accounts?id[$in][]=${payload.creatorId}`).catch(e => console.log(e));

          console.log('NEWLY CREATED TOP', newTop.data.data[0]);

          // add single top creator to the list
          context.commit('ADD_TOP_CREATOR', newTop.data.data[0]);

          // updated top creators
          console.log('UPDATED TOP-CREATORS', store.state.top_creators);

          // create list of usernames of new top creators
          const usernames = store.state.top_creators.reverse().map((c) => c.username).join(',');
          console.log('USERNAMES', usernames);

          const resp = await Axios.post(`https://${BASE_URL}/update-top-creators`, {usernames});
          console.log('UPDATE RESPONSE', resp);
        },
        REMOVE_TOP_CREATORS: async (context, payload) =>{
          console.log('ANDREI - Removing top creators');
          let {
              data
          } = await Axios.get(
              `https://${BASE_URL}/top-creators?creatorId[$in][]=${payload.creatorId}`).catch(e => console.log(e));
          await Axios.delete(`https://${BASE_URL}/top-creators/${data.data[0].id}`);
        },
        FIND_USERS: async (context, payload) => {
            let {
                data
            } = await Axios.get(
                `https://${BASE_URL}/search/accounts/${payload}&$sort[updated]:1`).catch(e => console.log(e));
            context.commit('SET_SEARCH_USER', data);
        },
        GET_POSTS: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/posts/${payload}&$sort[updated]:1`);
            context.commit(`SET_POSTS`, data);
        },
        SAVE_POST: async (context, payload) => {
            let {
                data
            } = await Axios.post(`https://${BASE_URL}/posts`);
            context.commit('ADD_POST', payload);
        },
        GET_ALL_POSTS: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/posts?accountId=${payload}&$sort[created]:1`);
            context.commit('SET_ALL_POSTS', data.data);
        },
        GET_PAYMENT_REQUESTS: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/requested-payments${payload}&$sort[created]:1`);
            context.commit('SET_PAYMENT_REQUESTS', data);
        },
        APPROVE_PAYMENT: async (context, payload) => {
            let {
                data
            } = await Axios.patch(`https://${BASE_URL}/requested-payments/${payload.id}`, payload.status);
            context.commit('SET_PAYMENT_APPROVE', data);
        },
        MODIFY_USER: async (context, payload) => {
            let {
                data
            } = await Axios.patch(`https://${BASE_URL}/accounts/${payload.id}`, payload.status);
            context.commit('SET_MODIFIED_USER', data);
        },
        GET_BLOCKED_USERS: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/blocked`);
            context.commit('SET_BLOCKED_USERS', data.data);
        },
        LOGIN({
            commit
        }, payload) {
            console.log('ANDREI', BASE_URL);
            return new Promise((resolve, reject) => {
                commit('auth_request')
                Axios({
                        url: `https://${BASE_URL}/authentication`,
                        data: payload,
                        method: 'POST'
                    })
                    .then(resp => {

                        const token = resp.data.accessToken
                        const id = resp.data.account.id
                        const user = resp.data.account
                        const isAdmin = resp.data.account.isAdmin || false

                        localStorage.setItem('id', id)

                        commit('SET_ADMIN', isAdmin)

                        Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                        commit('auth_success', token, user)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        reject(err)
                    })
            })
        },
        LOGOUT({
            commit
        }) {
            return new Promise((resolve, reject) => {
                commit('logout')
                localStorage.removeItem('id')
                localStorage.removeItem('admin-current-page')

                delete Axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
        RESET_PASSWORD: async (context, payload) => {
            let {
                data
            } = await Axios.post(`https://${BASE_URL}/authmanagement`, payload);
            context.commit('SET_NEW_PASSWORD', data);
        },
        GET_PROFILE_CONTENT: (context, payload) => {
            context.commit('SET_PROFILE_CONTENT', payload);
        },
        GET_PROFILE_INFO: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/account-details${payload}`);
            context.commit('SET_PROFILE_INFO', data);
            context.commit('SET_ADMIN', data.isAdmin || false);
        },
        WITHDRAW_EARNINGS: async (connext, payload) => {
            let {
                data
            } = await Axios.post(`https://${BASE_URL}/requested-payments`, payload);
            connext.commit('SET_EARNINGS', data);
        },
        GET_EARNINGS: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/earnings${payload}`);
            context.commit(`SET_EARNINGS`, data);
        },
        GET_MY_BALANCE: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/balances/${payload}`);
            context.commit(`SET_MY_BALANCE`, data);
        },
        GET_USD_AMOUNT: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/coin-exchange-rate/USD?amount=${payload}`)
            context.commit(`SET_USD_AMOUNT`, data.amount);
        },
        GET_USER_INFO: async (context, payload) => {
            let data = await Axios.get(`https://${BASE_URL}/account-details/${payload}`);
            context.commit(`SET_USER_INFO`, data.data);
        },
        GET_PAGE_CONTENT: (context, payload) => {
            context.commit(`SET_PAGE_CONTENT`, payload);
        },
        GET_MESSAGES: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/messages${payload}`).catch(err => console.log(err));
            context.commit(`SET_MESSAGES`, data);
        },
        GET_NOTIFICATIONS: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/messages?toAccountId=${payload}&$sort[created]:1`).catch(err => console.log(err));
            context.commit(`SET_NOTIFICATIONS`, data);
        },
        GET_UNREAD_NOTIFICATIONS: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/messages?toAccountId=${payload}&status=UNREAD&$sort[created]:1`)
                .catch(err => console.log(err));
            context.commit(`SET_UNREAD_NOTIFICATIONS`, data);
        },
        READ_NOTIFICATION: async (context, payload) => {
            await Axios.patch(`https://${BASE_URL}/messages/${payload.id}`, payload.status);
        },
        SEND_MESSAGE: async (context, payload) => {
            await Axios.post(`https://${BASE_URL}/messages`, payload);
        },
        CREATE_POST_REACTION: async (context, payload) => {
            await Axios.post(`https://${BASE_URL}/post-reactions`, payload);
        },
        BLOCK_POST: async (context, payload) => {
            await Axios.patch(`https://${BASE_URL}/posts/${payload.id}`, payload.status);
        },
        GET_FEED: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/feeds`).catch(err => console.log(err));
            context.commit(`SET_FEED`, data);
        },
        GET_REACTIONS: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/post-reactions${payload}`);
            context.commit(`SET_REACTIONS_ARRAY`, data);
        },
        REMOVE_REACTION: async (context, payload) => {
            await Axios.delete(`https://${BASE_URL}/post-reactions/${payload}`);
        },
        GET_ADMIN_CONTENT: (context, payload) => {
            context.commit(`SET_ADMIN_CONTENT`, payload);
            localStorage.setItem('admin-current-page', JSON.stringify(payload));
        },
        GET_FOLLOWERS_FOLLOWINGS: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/followers${payload}&$sort[created]:1`);
            context.commit(`SET_FOLLOWERS_FOLLOWINGS`, data);
        },
        GET_FAVORITES: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/favourites${payload}`);
            context.commit(`SET_FAVORITES`, data);
        },
        GET_REPORTS: async (context, payload) => {
            let queryArr = [];
            let arr1 = [];
            payload.ids.map((id) => {
                queryArr.push(`targetId[$in][]=${id}&`);
            });
            let query = queryArr.join(``);
            let arr2 = await Axios.get(`https://${BASE_URL}/reports?type=${payload.subject}&${query}`);
            arr2.data.data.map((report) => {
                arr1.push(report.targetId);
            });
            let count = (array) => {
                let names = {};
                array.forEach(item => {
                    names[item] = (names[item] || 0) + 1;
                });
                return names;
            }
            context.commit(`SET_REPORTS`, count(arr1));
        },
        GET_REPORTS_ARRAY: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/reports${payload}&$sort[updated]:1`);
            context.commit(`SET_REPORTS_ARRAY`, data);
        },
        GET_LIKES_AMOUNT: async (context, payload) => {
            let queryArr = [];
            let arr1 = [];
            payload.map((id) => {
                queryArr.push(`postId[$in][]=${id}&`);
            });
            let query = queryArr.join(``);
            let arr2 = await Axios.get(`https://${BASE_URL}/post-reactions?reaction=LIKE&${query}`);
            arr2.data.data.map((post) => {
                arr1.push(post.postId);
            });
            let count = (array) => {
                let names = {};
                array.forEach(item => {
                    names[item] = (names[item] || 0) + 1;
                });
                return names;
            }
            context.commit(`SET_LIKES_AMOUNT`, count(arr1));
        },
        GET_DISLIKES_AMOUNT: async (context, payload) => {
            let queryArr = [];
            let arr1 = [];
            payload.map((id) => {
                queryArr.push(`postId[$in][]=${id}&`);
            });
            let query = queryArr.join(``);
            let arr2 = await Axios.get(`https://${BASE_URL}/post-reactions?reaction=DISLIKE&${query}`);
            arr2.data.data.map((post) => {
                arr1.push(post.postId);
            });
            let count = (array) => {
                let names = {};
                array.forEach(item => {
                    names[item] = (names[item] || 0) + 1;
                });
                return names;
            }
            context.commit(`SET_DISLIKES_AMOUNT`, count(arr1));
        },
        GET_RATING: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/account-rating${payload}`);
            context.commit(`SET_RATING`, data);
        },
        GET_MY_PURCHASED_POSTS: async (context, payload) => {
            let queryArr = [];
            payload.map((id) => {
                queryArr.push(`id[$in][]=${id}&`);
            });
            let query = queryArr.join(``);
            let purchased = await Axios.get(`https://${BASE_URL}/posts?${query}`);
            context.commit(`SET_MY_PURCHASED_POSTS`, purchased.data);
        },
        GET_MY_SAVED_POSTS: async (context, payload) => {
            let queryArr = [];
            payload.map((id) => {
                queryArr.push(`id[$in][]=${id}&`);
            });
            let query = queryArr.join(``);
            let saved = await Axios.get(`https://${BASE_URL}/posts?${query}`);
            context.commit(`SET_MY_SAVED_POSTS`, saved.data);
        },
        GET_COMMENTS: async (context, payload) => {
            let {
                data
            } = await Axios.get(`https://${BASE_URL}/comments${payload}`);
            context.commit(`SET_COMMENTS`, data);
        }
    },
});
