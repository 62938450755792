<template>
  <div id="app">
    <Header v-if="this.$router.currentRoute.path == '/content' || this.$router.currentRoute.path == '/content/feed'" />
    <router-view />
    <Footer
      v-if="
        this.$router.currentRoute.path !== '/start' &&
          this.$router.currentRoute.path !== '/' &&
          this.$router.currentRoute.path !== '/withdraw' &&
          this.$router.currentRoute.path !== '/new-password' &&
          this.$router.currentRoute.path !== '/admin-panel' &&
          this.$router.currentRoute.path !== '/reset-password'
      "
    />
  </div>
</template>

<script>
import Header from "../src/components/Partials/Header.vue";
import Footer from "../src/components/Partials/Footer";

export default {
  name: "App",
  components: {
    Footer,
    Header,
  },
  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch(LOGOUT);
        }
        throw err;
      });
    });
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

#app {
  min-height: 100vh;
  background-color: $bgColorMain;
  margin: 0;
  padding: 0;
}

html {
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: $bgColorMain;
}

body {
  width: 100%;
  min-width: 318px;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", "times new roman", "times", "roman", "serif";
  background-color: $bgColorMain;
}
textarea {
  resize: none;
}
.wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.blur {
  filter: blur(9px);
  -webkit-filter: blur(9px);
}
</style>