<template>
  <section
    class="withdraw-page d-flex flex-column justify-content-between align-items-center"
  >
    <form
      class="withdraw-page_form d-flex flex-column justify-content-between align-items-center"
      @submit.prevent="withdraw"
    >
      <h3 class="h3 form-title m-0">
        Withdraw
      </h3>
      <p class="form-info m-0">
        Withdraw Earnings to PayPal
      </p>
      <label
        for="input-coins"
        class="input-coins-label"
      >
        Available coins
      </label>
      <input
        id="input-coins"
        v-model="coinsAmount"
        type="text"
        name="input-coins"
        readonly
      >
      <label
        for="input-usd"
        class="input-usd-label"
      > USD Equivalent </label>
      <input
        id="input-usd"
        v-model="dollarAmount"
        type="text"
        name="input-usd"
        readonly
      >
      <label
        for="input-paypal"
        class="input-paypal-label"
      >
        PayPal Email
      </label>
      <input
        id="input-paypal"
        v-model="email"
        type="email"
        name="input-paypal"
        placeholder="PayPal Email"
        required
      >
      <button
        type="submin"
        class="btn withdraw-btn"
        :disabled="isBtnDisabled"
      >
        withdraw
      </button>
    </form>
    <section
      v-if="isModalShow"
      class="withdraw-modal d-flex flex-column justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100 bg-dark p-5"
    >
      <p class="fs-4 text-white">
        Your withdraw request has been sent.
      </p>
      <router-link
        to="/content"
        class="btn btn-link text-white mt-5"
      >
        Back to Profile
      </router-link>
    </section>
    <section
      v-if="isWithdrawError"
      class="withdraw-error-modal d-flex flex-column justify-content-center align-items-center position-absolute top-50 start-50 translate-middle bg-dark"
    >
      <p class="fs-4 text-white text-center">
        {{ errorMsg }}
      </p>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeWithdrawError"
      >
        Ok
      </button>
    </section>
    <Footer />
  </section>
</template>

<script>
import Footer from "../components/Partials/Footer";

export default {
  name: "WithdrawPage",
  components: {
    Footer,
  },
  data() {
    return {
      isModalShow: false,
      email: ``,
      isWithdrawError: false,
      errorMsg: `Error`,
      isBtnDisabled: true,
      coinsAmount: 0,
      dollarAmount: 0
    };
  },
  mounted() {
    this.$store.dispatch(`GET_USER_INFO`, localStorage.getItem(`id`))
    .then(() => {
      this.email = this.$store.state.user_info[0].email;
    })
    this.$store.dispatch(`GET_MY_BALANCE`, localStorage.getItem(`id`))
    .then(() => {
      this.coinsAmount = this.$store.state.my_balance.available.earnings;
      if (this.coinsAmount > 0) {
        this.$store.dispatch(`GET_USD_AMOUNT`, this.coinsAmount).then(() => {
          this.dollarAmount = this.$store.state.usd_amount;
          this.isBtnDisabled = false;
        })
      };
    });
  },
  methods: {
    withdraw() {
      const objToSend = {
        amount: this.$store.state.my_balance.total,
        paypalEmail: this.email
      };
      this.$store
        .dispatch(`WITHDRAW_EARNINGS`, objToSend)
        .then(() => {
          this.isModalShow = true;
        })
        .catch((err) => {
          this.isWithdrawError = true;
          this.errorMsg = `We cannot proceed with your request, please call to the support service.`;
          console.log(err.response.data.message);
        });
    },
    closeWithdrawError() {
      this.isWithdrawError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

.withdraw-page {
  height: 100vh;
  min-height: 700px;
  background-image: url("../assets/img/withdraw-bg.png");
  background-size: cover;
}
.withdraw-page_form {
  width: 280px;
  min-height: 500px;
  background-color: #ffffff;
  border: solid 1px transparent;
  border-radius: 20px;
  padding: 1em;
  margin-top: 7em;

  @include media-breakpoint-up(md) {
    width: 420px;
    min-height: 510px;
    padding: 1.3em;
  }
}
.form-title {
  font-weight: bold;
  font-size: $font-size-large;
  line-height: 28px;
  color: #1d1e1f;
}
.form-info {
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-small;
  line-height: 140%;
  text-align: center;
  color: #1d1e1f;
  opacity: 0.7;
}
.withdraw-btn {
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-small;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #1d1e1f;
  width: 165px;
  height: 52px;
  background-color: #5cc5de;
  border-radius: 41px;
  border: transparent;
}
.input-coins-label,
.input-usd-label,
.input-paypal-label {
  align-self: flex-start;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-small;
  line-height: 15px;
  color: #1d1e1f;
}
#input-coins,
#input-usd,
#input-paypal {
  width: 100%;
  height: 45px;
  font-size: $font-size-small;
  color: #5cc5de;
  background-color: #ffffff;
  border-radius: 10px;
  border: transparent;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  padding: 1em 1em 1em 2.5em;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: 7px 14px;
  @include media-breakpoint-up(md) {
    width: 100%;
  }
}
#input-usd {
  background-size: 11px 18px;
  background-image: url("../assets/img/icon-dollar-withdraw.png");
}
#input-coins {
  background-image: url("../assets/img/icon-b-coin.png");
}
#input-paypal {
  background-image: url("../assets/img/icon-paypal.png");
}
.withdraw-modal {
  background-image: url("../assets/img/withdraw-bg.png");
  background-size: cover;
}
.withdraw-error-modal {
  width: 100%;
  height: 32em;
  border: solid 1px transparent;
  @include media-breakpoint-up(md) {
    width: 33em;
    height: 33em;
  }
}
</style>