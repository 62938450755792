<template>
  <section class="start-page w-100">
    <main
      class="start-page_content w-100 d-flex flex-column align-items-center"
    >
      <h1 class="h1 fw-bold text-white mt-5">
        Welcome to Brij!
      </h1>
      <p class="h3 text-white mt-5 text-center">
        A social app where you can finally reach anyone.
      </p>
      <form
        class="login-form d-flex flex-column justify-content-between mt-5"
        @submit.prevent="login"
      >
        <h2 class="login-form__title m-0">
          Log in
        </h2>
        <input
          id="user_email"
          v-model="email"
          type="email"
          class="user_email form-control"
          name="email"
          aria-describedby="emailHelp"
          placeholder="Email"
          required
        >
        <div
          class="position-relative form-group d-flex justify-content-around align-items-center"
        >
          <input
            id="user_password"
            v-model="password"
            type="password"
            class="user_password form-control"
            name="password"
            placeholder="Password"
            required
          >
          <label
            class="show-password-label position-absolute top-0 end-0"
            for="show-password"
          ><input
            id="show-password"
            type="checkbox"
            @change="myFunction"
          ></label>
        </div>
        <div
          class="login-form__button-wrap d-flex flex-row justify-content-between"
        >
          <router-link
            to="/reset-password"
            class="btn btn-link btn-sm text-white"
          >
            Forgot password?
          </router-link>

          <button
            id="submit-btn"
            type="submit"
            class="btn btn-secondary btn-block w-50"
          >
            <span class="text-black">Submit</span>
          </button>
        </div>
        <div
          v-if="isError"
          class="error-popup position-absolute top-0 start-0 bg-dark p-3"
        >
          <div class="error-wrap d-flex flex-column">
            <button
              type="button"
              class="btn-close btn-close-white ms-auto"
              aria-label="Close"
              @click="closeerror()"
            />
            <h2 class="text-white text-center">
              Ooops!
            </h2>
            <p class="text-white text-center">
              Something went wrong.
            </p>
            <p class="text-white text-center">
              {{ errorMessageText }}
            </p>
          </div>
        </div>
      </form>
      <div class="app-links links-wrap d-flex justify-content-between mt-5">
        <a
          class="app-links_apple d-flex justify-content-center align-items-center"
          href="https://apps.apple.com/ua/app/brij-create-share-earn/id1494871435"
        />
        <a
          class="app-links_google d-flex justify-content-center align-items-center"
          href="#"
        />
      </div>
    </main>
    <Footer />
  </section>
</template>

<script>
import Footer from "../components/Partials/Footer";

export default {
  name: "StartPage",
  components: {
    Footer,
  },

  data() {
    return {
      email: "",
      password: "",
      isError: false,
      errorMessageText: ``
    };
  },
  methods: {
    login() {
      let strategy = `local`;
      let email = this.email;
      let password = this.password;

      this.$store
        .dispatch("LOGIN", { strategy, email, password })
        .then(() => {
          let isAdmin = this.$store.state.is_admin;
          if (isAdmin) {
            this.$router.push("/admin-panel");
          } else {
            this.$router.push("/content");
          }
        })
        .catch((err) => {
          if (err.response.status == `401`) {
            this.errorMessageText = `The email or password you entered is not valid. Please try again.`
            this.isError = true;
          }
          if (err.response.status == `500`) {
            this.errorMessageText = `Please, check your Internet connection or try again later.`
            this.isError = true;
          }
        })
        .catch((e) => {
          this.isError = false;
        });
    },
    myFunction() {
      let x = document.querySelector(`#user_password`);
      let iconEye = document.querySelector(`.show-password-label`);
      iconEye.classList.toggle(`visible`);
      if (x.type === `password`) {
        x.type = `text`;
      } else {
        x.type = `password`;
      }
    },
    closeerror() {
      this.isError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

body {
  overflow-y: hidden;
}

.start-page {
  min-height: 700px;
  height: 100vh;
  background-image: url("../assets/img/welcome-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(44, 30, 44);
  border: solid 1px transparent;
}
.start-page_content {
  min-height: calc(100vh - 52px);
}
.links-wrap {
  width: 220px;
  margin-bottom: 2em;
  opacity: 0.7;
}
.app-links_apple {
  width: 100px;
  min-height: 30px;
  background-image: url("../assets/img/icon-apple.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 115% auto;
}
.app-links_google {
  width: 100px;
  min-height: 30px;
  background-image: url("../assets/img/icon-google.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.show-password-label {
  width: 40px;
  height: 40px;
  background-image: url("../assets/img/icon-eye-close.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  cursor: pointer;
}
.visible {
  background-image: url("../assets/img/icon-eye.png");
}
#show-password {
  display: none;
}
.login-form {
  position: relative;
  width: 18em;
  height: 15em;
  background-color: transparent;

  @include media-breakpoint-up(md) {
    width: 18em;
    height: 13em;
  }
  @include media-breakpoint-up(lg) {
    width: 20em;
    height: 13em;
  }
  @include media-breakpoint-up(xl) {
    width: 22em;
    height: 13em;
  }
}
.login-form__submit {
  width: 95px;
  height: 42px;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-small;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #1d1e1f;
  background-color: #5cc5de;
  border: none;
  border-radius: 50px;
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
  margin: 0;
  padding: 0;
}

.login-form__title {
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-xxl;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
  width: auto;
}

.error-popup {
  width: 100%;
  height: 100%;
  border: solid 1px transparent;
}
.hidden {
  display: none;
}
</style>