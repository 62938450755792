<template lang="html">
  <div class="user-status-btn">
    <div class="user-status">
      <button
        type="button"
        class="button"
        data-title="Top"
        :class="{active: isTop()}"
        @click="topUserToggle()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="button-image"
        >
          <path d="m21.5 9.757-5.278 4.354 1.649 7.389L12 17.278 6.129 21.5l1.649-7.389L2.5 9.757l6.333-.924L12 2.5l3.167 6.333z" />
        </svg>
      </button>
      <button
        type="button"
        class="button"
        data-title="Veified"
        :class="{active: isVerify}"
        @click="verifyUserToggle()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="button-image"
          viewBox="0 0 32 32"
        >
          <path d="M15.74 31C.15 26.8 1 8.13 1 7.94a1 1 0 0 1 .6-.86l14-6a1 1 0 0 1 .78 0l14 6a1 1 0 0 1 .6.86c0 .19.86 18.86-14.73 23a1.14 1.14 0 0 1-.51.06zM3 8.66C3 17.87 7.06 26.44 16 29c12.28-3.5 13-17.26 13-20.34L16 3.09z" />
          <path d="M15.67 26.77C7 23.77 5.35 14.53 5.06 10.69a1 1 0 0 1 .6-1l10-4.27a1 1 0 0 1 .78 0l10 4.27a1 1 0 0 1 .6 1c-.39 3.84-2.04 13.08-10.71 16.08a1.06 1.06 0 0 1-.66 0zM7.12 11.25c.45 4.28 2.24 11 8.88 13.51 6.65-2.49 8.44-9.23 8.88-13.51L16 7.44z" />
          <path d="m14.29 18.71-2-2a1 1 0 0 1 1.42-1.42l1.29 1.3 3.29-3.3a1 1 0 0 1 1.42 1.42l-4 4a1 1 0 0 1-1.42 0z" />
        </svg>
      </button>
      <button
        type="button"
        class="button"
        data-title="Private"
        :class="{active: isPrivate}"
        @click="privateUserToggle()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="button-image"
        >
          <path d="M9.56 5.24C9.124 6.011 9 7.05 9 8a1 1 0 0 1-2 0c0-1.05.125-2.511.816-3.74a4.256 4.256 0 0 1 1.58-1.625C10.113 2.22 10.98 2 12 2c1.021 0 1.887.22 2.603.635a4.256 4.256 0 0 1 1.581 1.625C16.875 5.489 17 6.95 17 8a1 1 0 1 1-2 0c0-.95-.125-1.989-.56-2.76a2.258 2.258 0 0 0-.84-.875C13.238 4.155 12.729 4 12 4c-.729 0-1.238.155-1.6.365-.362.21-.634.507-.84.875zM10 14a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
          <path d="M12 14a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" />
          <path d="M3 11a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-8zm3-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1H6z" />
        </svg>
      </button>
      <button
        type="button"
        class="button"
        data-title="Restrict"
        :class="{active: isBlock==='BLOCKED'}"
        @click="blockUserToggle()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="button-image"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.929 19.07a1 1 0 0 1 0-1.413L17.657 4.929a1 1 0 1 1 1.414 1.414L6.343 19.07a1 1 0 0 1-1.414 0z"
          />
        </svg>
      </button>
      <button
        type="button"
        class="button"
        data-title="Delete"
        @click="deleteUser()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="button-image"
        >
          <path d="M6 7a1 1 0 0 1 1 1v11a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V8a1 1 0 1 1 2 0v11a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V8a1 1 0 0 1 1-1z" />
          <path d="M10 8a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1zM14 8a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1zM4 5a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1zM8 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1z" />
        </svg>
      </button>
    </div>
    <div
      v-if="showpopup"
      class="reason-popup bg-dark"
    >
      <form @submit.prevent="submitReason()">
        <label
          class="text-white mt-1"
        >Reason:
          <textarea
            class="w-100 mt-1"
            name="story"
            rows="4"
            cols="23"
            disabled
            :placeholder="reasonPlaceholder"
          />
        </label>
        <div class="btn-reason-group">
          <button
            class="button-reason"
            type="submit"
            data-title="Save changes"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="button-image button-image-reason button-image-reason_confirm"
            >
              <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
            </svg>
          </button>
          <button
            type="button"
            class="button-reason"
            data-title="Cancel"
            @click="closePopup()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="button-image button-image-reason button-image-reason_reject"
            >
              <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3.707,12.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414L13.414,12Z" />
            </svg>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name : "AdminUsersStatusBtn",
  props: {
    id: {
      type: String,
      required: true
    },
    isRestricted: {
      type: String,
      required: false,
      default: ""
    },
    isPrivateByAdmin: Boolean,
    hasBadge: Boolean,
    modifyUser: {
      type: Function,
      default: function () {}
    },
    modifyTopStatus: {
      type: Function,
      default: function () {}
    }
  },
  data(){
    return{
      showpopup: false,
      reasonPlaceholder:'',
      actionType:'',
      modifyData:{},
      isPrivate:this.isPrivateByAdmin,
      isBlock:this.isRestricted,
      isVerify:this.hasBadge,
      reasons:{
        private: {
          set: 'Your profile may contain content unsuitable for our global community and has been made private',
          remove: 'Your page has changed its status from private to public.',
        },
        block: {
          set: 'Your page has been restricted for unusual activity.',
          remove: 'Your profile status has transitioned from restricted to public.',
        },
        top: {
          set: 'Your profile has been added to the top creators',
          remove: 'Your profile has been removed from top creators',
        },
        verify: {
          set: 'Your profile is verified',
          remove: 'Your profile was unverified',
        },
      },
      statusKeys: {
        private: 'isPrivateByAdmin',
        block: 'status',
        verify: 'hasBadge',
      }
    }
  },
  methods: {
    showpopupToggle(){
      this.showpopup=!this.showpopup;
    },
    clearModifyData(){
      this.reasonPlaceholder = '';
      this.actionType =''
      this.modifyData = {};
      this.showpopupToggle();
    },
    preparationModifyData(actionType, statusValue, message){
      this.actionType = actionType;
      if(actionType !== 'top'){
        this.modifyData = {
          id: this.id,
          status: {
            [this.statusKeys[actionType]]: statusValue,
            statusMessage: message,
          },
        };
      }
      this.reasonPlaceholder = message;
      this.showpopupToggle();
    },
    topUserToggle() {
      const statusValue = '';
      const message = this.isTop() ? this.reasons.top.remove : this.reasons.top.set;
      this.preparationModifyData('top', statusValue, message);
    },
    verifyUserToggle(){
      const statusValue = !this.isVerify;
      const message = this.isVerify ? this.reasons.verify.remove : this.reasons.verify.set;
      this.preparationModifyData('verify', statusValue, message);
    },
    privateUserToggle(){
      const statusValue = !this.isPrivate;
      const message = this.isPrivate ? this.reasons.private.remove : this.reasons.private.set;
      this.preparationModifyData('private', statusValue, message);
    },
    blockUserToggle(){
      const statusValue = this.isBlock === 'BLOCKED' ? 'APPROVED' : 'BLOCKED';
      const message = this.isBlock === 'BLOCKED' ? this.reasons.block.remove : this.reasons.block.set;
      this.preparationModifyData('block', statusValue, message);
    },
    closePopup(){
      this.clearModifyData();
    },
    submitReason() {
      if(this.actionType === 'block') this.isBlock==='BLOCKED' ? this.isBlock = 'APPROVED' : this.isBlock = 'BLOCKED';
      if(this.actionType === 'private') this.isPrivate=!this.isPrivate;
      if(this.actionType === 'verify') this.isVerify=!this.isVerify;
      if(this.actionType==='block' || this.actionType==='private' || this.actionType === 'verify'){
        this.modifyUser(this.modifyData);
      }
      if(this.actionType==='top'){
        this.isTop() ? this.modifyTopStatus('remove', this.id) : this.modifyTopStatus('create', this.id);
      }

      this.clearModifyData();
    },
    isTop(){
      return this.$store.state.top_creators.find((creator)=>creator.id===this.id);
    },
  }
}
</script>

<style lang="css" scoped>
.user-status-btn{
  width: 95%;
}
.user-status{
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 0;
  position: relative;
}
.user-status .button{
  width:40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.button-image{
  width: 25px;
  height: 25px;
  fill: #333335;
}
.button{
  background-color: rgba(255, 255, 255, 0.5);
}
.button:hover{
  background-color: rgba(255, 255, 255, 0.8);
}
.button:hover::after{
  content: attr(data-title);
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.5);
}
.active{
  background-color: #0dcaf0;
}
.reason-popup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  font-size: 15px;
}
.reason-popup form{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.btn-reason-group{
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 150px;
}
.button-reason{
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: none;
  display: block;
  background: none;
  height: fit-content;
}
.button-reason:hover::after{
  content: attr(data-title);
  position: absolute;
  bottom: 65px;
  left: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.5);
}
.button-image-reason{
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background:rgba(255,255,255,.5);
}
.button-image-reason_confirm:hover{
  background: #198754;
}
.button-image-reason_reject:hover{
  background:#dc3545;
}
.users-item-search .user-status .button{
  width: 29px;
  height: 29px;
}
.users-item-search .user-status{
  min-width: 0;
}
.users-item-search .reason-popup form{
  flex-direction: row;
}
.users-item-search .reason-popup label{
  display: flex;
  align-items: center;
  margin-top: 0!important;
  width: 90%;
  justify-content: space-around;
}
.users-item-search .reason-popup textarea{
  margin-top: 0!important;
  width: 100%!important;
  height: 100%;
}
.users-item-search .button-reason:hover::after{
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
}

</style>
