var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(this.$router.currentRoute.path == '/content' || this.$router.currentRoute.path == '/content/feed')?_c('Header'):_vm._e(),_c('router-view'),(
      this.$router.currentRoute.path !== '/start' &&
        this.$router.currentRoute.path !== '/' &&
        this.$router.currentRoute.path !== '/withdraw' &&
        this.$router.currentRoute.path !== '/new-password' &&
        this.$router.currentRoute.path !== '/admin-panel' &&
        this.$router.currentRoute.path !== '/reset-password'
    )?_c('Footer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }