<template lang="html">
  <div
    v-if="this.$store.state.users.total!==0"
    class="pagination-wrap"
  >
    <PaginationNavigation />
  </div>
</template>

<script>
import PaginationNavigation from "../../Partials/PaginationNavigation.vue";
export default {
  name: "AdminUsersPagination",
  components: {
    PaginationNavigation,
  },
}
</script>

<style lang="css" scoped>
  .pagination-wrap {
    width: 100%;
    min-height: 4em;
  }
</style>
