<template>
  <header
    class="my-profile__head container-fluid bg-dark fixed-top"
    tabindex="0"
    @keyup.esc="closeMsg"
  >
    <button
      class="bg-transparent border-0"
      type="button"
      @click="getProfile"
    >
      <img
        class="my-profile__logo"
        src="../../assets/img/icon-logo.png"
        alt="logo"
        width="38"
        height="38"
      >
    </button>

    <div class="my-profile__controls bg-dark">
      <router-link
        class="my-profile__controls-home btn"
        type="button"
        to="/content/feed"
      />

      <router-link
        class="my-profile__controls-profile btn"
        type="button"
        to="/content"
      />

      <div class="dropdown">
        <button
          class="my-profile__controls-msg btn"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          @click="showMessages"
        >
          <span
            v-if="this.$store.state.unread_notifications.total > 0"
            class="
              msg-dot
              text-white
              d-flex
              justify-content-center
              align-items-center
            "
          >{{ this.$store.state.unread_notifications.total }}</span>
        </button>
        <div class="dropdown-menu msg-menu bg-transparent">
          <div class="msg-box bg-dark">
            <p class="text-white fs-5 m-3 fw-bold">
              You have {{ this.$store.state.unread_notifications.total }} new
              messages:
            </p>
            <section class="message-box w-100 text-white">
              <div
                v-for="(msg, i) in messagesList"
                :key="i"
                class="
                  message-item
                  d-flex
                  flex-column
                  justify-content-center
                  border-start border-end
                "
                @click.stop.prevent="checkMessage(msg, i)"
              >
                <div class="row m-0">
                  <div class="message-item__sender-info col-3">
                    <img
                      :src="getAvatarUrl(msg.accountId)"
                      alt="avatar"
                      width="65"
                      height="65"
                      class="rounded-circle"
                    >
                    <span
                      v-if="usersInfoArray.length > 0"
                      class="message-item__username"
                    >{{ usersInfoArray.get(msg.accountId).username }}</span>
                    <span v-else>Username</span>
                  </div>
                  <div class="col-6">
                    <span
                      class="d-block"
                      :class="{ 'text-truncate': isTextExpanded !== msg.id }"
                    >{{ msg.data[0].content }}</span>

                    <p
                      class="msg-date mt-3"
                      :class="{ 'msg-date-unread': msg.status == 'UNREAD' }"
                    >
                      {{ getDateFromNow(msg.created) }}
                    </p>
                    <span
                      v-if="msg.status == 'UNREAD'"
                      class="msg-unread-dot"
                    />
                  </div>
                  <div
                    v-if="msg.data[0].postInfo"
                    class="col p-0"
                  >
                    <img
                      class="img-fluid"
                      :src="getPostUrl(msg.data[0].postInfo)"
                      alt="image"
                      width="69"
                      height="auto"
                    >
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div class="dropdown">
        <button
          class="my-profile__settings btn"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        />
        <div class="dropdown-menu logout-menu bg-dark">
          <router-link
            class="dropdown-item text-white"
            to="/content/my/edit"
          >
            Edit profile
          </router-link>
          <button
            type="button"
            class="dropdown-item text-white"
            @click="logout"
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isMsgOpen: false,
      isTextExpanded: false,
      usersInfoArray: new Map(),
      hasUserData: false,
      myId: ``,
    };
  },
  computed: {
    messagesList() {
      return [...new Set(this.$store.state.notifications.data)];
    },
  },
  mounted() {
    this.myId = localStorage.getItem(`id`);
    const obj = {
      showProfile: true,
      showFeeds: false,
    };
    this.$store.dispatch(`GET_PAGE_CONTENT`, obj);
    this.$store.dispatch(`GET_NOTIFICATIONS`, this.myId).then(() => {
      let arr = [];
      this.$store.state.notifications.data.map((item) => {
        arr.push(item.accountId);
      });
      this.$store.dispatch(`GET_ALL_USERS_INFO`, arr).then(() => {
        this.usersInfoArray = this.$store.state.all_users_info;
        this.hasUserData = true;
      });
    });
    this.$store.dispatch(`GET_UNREAD_NOTIFICATIONS`, this.myId);
  },
  methods: {
    logout() {
      this.$store
        .dispatch("LOGOUT")
        .then(() => this.$router.push("/"))
        .catch((err) => console.log(err));
    },
    getProfile() {
      const obj = {
        showProfile: true,
        showFeeds: false,
      };
      this.$store.dispatch(`GET_PAGE_CONTENT`, obj);
    },
    // getFeeds() {
    //   const obj = {
    //     showProfile: false,
    //     showFeeds: true,
    //   };
    //   this.$store.dispatch(`GET_PAGE_CONTENT`, obj);
    // },
    checkMessage(m, i) {
      if (!this.isTextExpanded) {
        this.isTextExpanded = m.id;
      } else {
        this.isTextExpanded = false;
      }
      this.$store
        .dispatch(`READ_NOTIFICATION`, {
          id: m.id,
          status: {
            status: `READ`,
          },
        })
        .then(() => {
          this.$store.dispatch(`GET_NOTIFICATIONS`, this.myId);
          this.$store.dispatch(`GET_UNREAD_NOTIFICATIONS`, this.myId);
        })
        .catch((err) => console.log(err));
    },
    showMessages() {
      this.$store.dispatch(`GET_NOTIFICATIONS`, this.myId);
      this.$store.dispatch(`GET_UNREAD_NOTIFICATIONS`, this.myId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

.my-profile__head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $bgColorMain;
  border: solid 1px transparent;
  box-sizing: border-box;
  padding: 0px 5px 0px 5px;
  opacity: 0.9;

  @include media-breakpoint-up(md) {
    padding: 10px 16px 10px 16px;
  }
  @include media-breakpoint-up(lg) {
    padding: 9px 16px 9px 16px;
  }
  @include media-breakpoint-up(xl) {
  }
}
.my-profile__head :focus {
  outline: none !important;
  border-color: transparent;
  box-shadow: transparent;
}
[tabindex] {
  outline: none !important;
}
.my-profile__settings {
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  background-image: url("../../assets/img/icon-gear.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35px 35px;
}
.my-profile__controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-top: 0px;
  border: solid 1px transparent;
  background: rgba(29, 29, 29, 0.8);
  @include media-breakpoint-up(md) {
    width: 30%;
    height: 50px;
    margin-top: 0px;
  }
  @include media-breakpoint-up(lg) {
    width: 20%;
  }
}

.my-profile__controls-home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-image: url("../../assets/img/icon-home2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35px 35px;
}
.my-profile__controls-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-image: url("../../assets/img/icon-profile.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35px 35px;
}
.my-profile__controls-msg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  background-image: url("../../assets/img/icon-bell.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35px 35px;
  border: none;
}
.msg-dot {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background-color: red;
  transform: translate(44%, -47%);
  opacity: 0.8;
}
.my-profile__controls-post {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
}
.my-profile__controls-user {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.my-profile__controls-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  @include media-breakpoint-up(md) {
    width: 40px;
    height: 40px;
  }
}
.logout-menu {
  min-width: 6rem;
  transform: translate(-60%, 60%) !important;

  @include media-breakpoint-up(md) {
    min-width: 6rem;
    transform: translate(-54%, 70%) !important;
  }
}
.msg-menu {
  width: auto;
  height: auto;
  padding: 0;
  transform: translate3d(-220px, 45px, 0px);

  @include media-breakpoint-up(md) {
    min-width: 8rem;
    transform: translate(-90%, 14%) !important;
  }
}
.dropdown-item:hover {
  color: #000000 !important;
}
.dropdown-item:active {
  background-color: grey !important;
}
.hidden {
  display: none;
}
.msg-box {
  width: 19em;
  height: 15em;
  opacity: 1;
  overflow-y: auto;
  @include media-breakpoint-up(md) {
    width: 25em;
    height: 25em;
  }
}
.message-item {
  position: relative;
  width: 97%;
  min-height: 10em;
  font-size: $font-size-mobile;
  margin-top: 1em;
  cursor: pointer;
  @include media-breakpoint-up(md) {
    font-size: $font-size-small;
    padding-left: 10px;
  }
}
.msg-unread-dot {
  position: absolute;
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: $mainBlue;
  transform: translate(930%, -280%);
  @include media-breakpoint-up(md) {
    transform: translate(940%, -240%);
  }
}
.message-item:hover {
  background-color: $item-color;
}
.msg-date {
  font-weight: 300;
  font-style: italic;
}
.msg-date-unread {
  color: $mainBlue;
}
.message-item__sender-info {
  padding: 0;
}
.message-item__username {
  display: block;
  padding: 0.8em 0em 0em 0.3em;
}
</style>

