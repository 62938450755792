<template lang="html">
  <div>
    <ul
      class="users-list-general"
      :class="[isSearch ? 'users-list-search' : 'users-list-standart']"
    >
      <li
        v-for="(user, index) in users"
        :key="user.id"
        class="users-item-general"
        :class="[isSearch ? 'users-item-search' : 'users-item-standart']"
      >
        <div class="header_user">
          <div class="user-avatar">
            <img
              class="admin-user-card__avatar"
              :src="getAvatarUrl(user.id)"
              alt="avatar"
              onerror="this.onerror=null; this.src='../../images/default-avatar.jpeg'"
            >
            <div
              class="user-avatar-hover"
              @click="getProfile(user, index)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,15a1,1,0,0,1-2,0V11a1,1,0,0,1,2,0ZM12,8a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,8Z" />
              </svg>
            </div>
          </div>
          <div class="user-contact-info">
            <a 
              v-if="user.phone" 
              :href="`tel:${user.phone}`"
              class="admin-user-call"
            >
              <span
                class="details-phone"
              >
                {{ user.phone }}
              </span>
            </a>
            <a 
              v-if="user.email" 
              :href="'mailto:' + user.email" 
              class="admin-user-email"
            >
              <span 
                class="details-email"
              >
                {{ user.email }}
              </span>
            </a>
          </div>
        </div>
        <div class="user-names">
          <div class="user-username">
            {{ user.username || `Username` }}
          </div>
          <div class="user-name">
            {{ user.name || `Name not set` }}
          </div>
        </div>
        <AdminUsersStatusBtn
          :id="user.id"
          :is-restricted="user.status"
          :is-private-by-admin="user.isPrivateByAdmin"
          :has-badge="user.hasBadge"
          :modify-user="modifyUser"
          :modify-top-status="modifyTopStatus"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import AdminUsersStatusBtn from "./StatusBtn.vue";
export default {
  name:"AdminUsersList",
  components: {
    AdminUsersStatusBtn,
  },
  props: {
    users: {
      type: Array,
      default: function () {}
    },
    modifyUser: {
      type: Function,
      default: function () {}
    },
    modifyTopStatus: {
      type: Function,
      default: function () {}
    },
    isSearch:Boolean,
  },

  methods: {
    getProfile(user, i) {
      this.$router.push(`/profile/${user.id}`);
    },
    isTopUser(){
      return this.$store.state.top_creators.find((creator)=>creator.id===this.id);
    },
  }

}
</script>

<style lang="css" scoped>
.users-list-general{
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px 0 0
}
.users-list-standart{
  padding: 20px 0 0 0;
}
.users-list-search{
  padding:0;
  width: 100%;
}
.users-item-general{
  list-style-type: none;
  background-color: #333335;
  border: solid 1px rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  font-size: 0.813rem;
  box-sizing: border-box;
  position: relative;
}
.users-item-standart{
  width: 250px;
  height: 250px;
  justify-content: space-around;
  flex-direction: column;
  padding: 10px;
  margin: 0 5px 10px;
}
.users-item-search{
  width:100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 0;
  margin-bottom: 1px;
  justify-content: space-around;
}
.admin-user-card__avatar{
  width: 100px;
  height: auto;
  border-radius: 50%;
}
.header_user{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

}
.user-names{
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}
.user-username{
  width: 100%;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-name{
  opacity: 0.7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.admin-user-call{
  display: block;
    width: 38px;
    height: 38px;
    margin: 0px 0px 0px 0px;
    background-color: transparent;
    border: none;
    background-image: url(/img/icon-phone.4f5a681e.svg);
    background-size: 30px 30px;
    background-position: center;
    background-repeat: no-repeat;
}
.details-phone, .details-email{
  display: none;
}
.admin-user-call:hover .details-phone,
.admin-user-email:hover .details-email
{
  display: block;
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.admin-user-email {
  display: block;
  width: 38px;
  height: 38px;
  margin: 0px 0px 0px 0px;
  background-color: transparent;
  border: none;
  background-image: url("../../../assets/img/icon-email.svg");
  background-size: 30px 30px;
  background-position: center;
  background-repeat: no-repeat;
}
.user-avatar{
  position: relative;
}
.user-avatar-hover{
  display: none;
  width: 100px;
  height: 100px;
  fill: rgba(255,255,255,.8);
  border-radius: 50%;
  background-color: rgba(0,0,0,.7);
}
.user-avatar:hover .user-avatar-hover{
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}


.users-item-search .header_user{
  width: 25%;
}
.users-item-search .user-names{
  width: 40%;
}
.users-item-search .user-status-btn{
  width: 35%;
}
.users-item-search .admin-user-card__avatar{
  width: 50px;
}
.users-item-search .user-avatar-hover{
  width: 50px;
  height: 50px;
}
.users-item-search .admin-user-call, .users-item-search .admin-user-email{
  background-size: 25px 25px;
  width: 31px;
  height: 31px;
}
</style>
