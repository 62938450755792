<template lang="html">
  <div class="admin container position-relative admin-min-height active-users-link">
    <AdminGeneralTopBar />
    <AdminUsersNavBar />
    <AdminUsersSearchForm
      :users="usersList"
      :modify-user="modifyUser"
      :modify-top-status="modifyTopStatus"
    />
    <AdminUsersList
      :users="usersList"
      :modify-user="modifyUser"
      :modify-top-status="modifyTopStatus"
    />
    <!-- <AdminUsersPagination  v-if ="this.$store.state.users.total>50"/> -->
  </div>
</template>

<script>
import AdminUsersNavBar from "./NavBar";
import AdminUsersList from "./List";
// import AdminUsersPagination from "./Pagination";
import AdminGeneralTopBar from "../General/TopBar";
import AdminUsersSearchForm from "./SearchForm";
export default {
  name:"AdminUsersTop",
  components: {
    AdminGeneralTopBar,
    AdminUsersNavBar,
    AdminUsersList,
    // AdminUsersPagination,
    AdminUsersSearchForm,
  },
  data() {
    return{
      limit: this.$store.state.pagination_object.limit || 50,
      skip: 0
    }
  },
  computed: {
    usersList() {
      // console.log('VUE TOP STORE', this.$store.state.top_creators);
      return this.$store.state.top_creators;
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers(){
      this.$store.dispatch(
        `GET_TOP_CREATORS`
      );
    },
    modifyUser(data){
      this.$store
        .dispatch(`MODIFY_USER`, data)
        .then(() => {
          this.getUsers();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    modifyTopStatus(action, id){
      if(action=='create'){
        this.$store.dispatch(`SAVE_TOP_CREATORS`, {'creatorId':id})
        .then(() => {
          this.getUsers();
        })
        .catch((error) => {
          console.log(error.response);
        });
      }
      if(action==='remove'){
        this.$store.dispatch(`REMOVE_TOP_CREATORS`, {'creatorId':id})
        .then(() => {
          this.getUsers();
        })
        .catch((error) => {
          console.log(error.response);
        });
      }
    }
  },
}
</script>

<style lang="css" scoped>
.admin-min-height{
  min-height: calc(100vh - 130px);
}
.admin{
  margin-top: 50px;
}
</style>

<!-- <template lang="html">
  <div class="admin container pt-0 pb-5 position-relative admin-min-height">
    <AdminGeneralTopBar />
    <AdminUsersNavBar/>
    <AdminUsersSearchForm :updateUsers="getUsers"/>
    <AdminUsersList :users = "usersList" :modifyUser="modifyUser" :updateUsers="getUsers"/>
  </div>
</template>

<script>
import AdminUsersNavBar from "./AdminUsers-NavBar";
import AdminUsersList from "./AdminUsers-List";
import AdminGeneralTopBar from "../AdminGeneral/AdminGeneral-TopBar";
import AdminUsersSearchForm from "./AdminUsersSearch-Form";
export default {
  name:"AdminUsersTop",
  components: {
    AdminGeneralTopBar,
    AdminUsersNavBar,
    AdminUsersList,
    AdminUsersSearchForm,
  },
  methods: {
    getUsers(){
      this.$store.dispatch(
        `GET_TOP_CREATORS`
      );
    },
    modifyUser(data){
      this.$store
        .dispatch(`MODIFY_USER`, data)
        .then(() => {
          this.getUsers();
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  },
  computed: {
    usersList() {
      return this.$store.state.top_creators;
    },
  },
  mounted() {
    this.getUsers();
  },
}
</script>

<style lang="css" scoped>
.admin-min-height{
  min-height: calc(100vh - 130px);
}
.admin{
  margin-top: 50px;
}
</style> -->
