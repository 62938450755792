<template lang="html">
  <div class="admin container position-relative admin-min-height active-users-link">
    <AdminGeneralTopBar />
    <AdminUsersNavBar />
    <AdminUsersSearchForm
      :users="usersList"
      :modify-user="modifyUser"
      :modify-top-status="modifyTopStatus"
    />
    <AdminUsersList
      :users="usersList"
      :modify-user="modifyUser"
      :modify-top-status="modifyTopStatus"
    />
    <AdminUsersPagination v-if="this.$store.state.users.total>50" />
  </div>
</template>

<script>
import AdminUsersNavBar from "./NavBar";
import AdminUsersList from "./List";
import AdminUsersPagination from "./Pagination";
import AdminGeneralTopBar from "../General/TopBar";
import AdminUsersSearchForm from "./SearchForm";
export default {
  name:"AdminUsersBlocked",
  components: {
    AdminGeneralTopBar,
    AdminUsersNavBar,
    AdminUsersList,
    AdminUsersPagination,
    AdminUsersSearchForm,
  },
  data() {
    return{
      limit: this.$store.state.pagination_object.limit || 50,
      skip: 0
    }
  },
  computed: {
    usersList() {
      return this.$store.state.users.data;
    },
    getPaginationData() {
      return this.$store.state.pagination_object;
    },
  },
  watch: {
    getPaginationData(newVal, oldVal) {
      this.limit = newVal.limit;
      this.skip = newVal.skip;
      this.getUsers();
    },
    usersList(newVal, oldVal) {
      this.$store.commit(
        `SET_TOTAL_ITEMS_PAGINATION`,
        this.$store.state.users.total
      );
    },
  },
  mounted() {
    this.getUsers();
    this.getTop();
  },
  methods: {
    getUsers(){
      this.$store.dispatch(
        `GET_USER`,
        `?$limit=${this.limit}&$skip=${this.skip}&status[$in][]=BLOCKED`
      );
    },
    getTop(){
      this.$store.dispatch(
      `GET_TOP_CREATORS`
      );
    },
    modifyUser(data){
      this.$store
        .dispatch(`MODIFY_USER`, data)
        .then(() => {
          this.getUsers();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    modifyTopStatus(action, id){
      if(action=='create'){
        this.$store.dispatch(`SAVE_TOP_CREATORS`, {'creatorId':id})
        .then(() => {
          this.getTop();
        })
        .catch((error) => {
          console.log(error.response);
        });
      }
      if(action==='remove'){
        this.$store.dispatch(`REMOVE_TOP_CREATORS`, {'creatorId':id})
        .then(() => {
          this.getTop();
        })
        .catch((error) => {
          console.log(error.response);
        });
      }
    }
  },
}
</script>

<style lang="css" scoped>
.admin-min-height{
  min-height: calc(100vh - 130px);
}
.admin{
  margin-top: 50px;
}
</style>
