<template lang="html">
  <div>
    <nav class="admin-users-navbar">
      <router-link
        :to="{name:'AdminUsersAll'}"
        aria-current="page"
        class="nav-link"
        active-class="w--current"
        exact
      >
        All
      </router-link>
      <router-link
        :to="{name:'AdminUsersPrivate'}"
        class="nav-link"
        active-class="w--current"
      >
        Private
      </router-link>
      <router-link
        :to="{name:'AdminUsersBlocked'}"
        class="nav-link"
        active-class="w--current"
      >
        Restricted
      </router-link>
      <router-link
        :to="{name:'AdminUsersTop'}"
        class="nav-link"
        active-class="w--current"
      >
        Top
      </router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: "AdminUsersNavBar",
}
</script>

<style lang="css" scoped>
.admin-users-navbar{
  display: flex;
  justify-content: center;
}
.nav-link{
  font-weight: 400;
  width: 25%;
  min-width: 100px;
  text-align: center;
  padding: 6px 12px;
  border: 1px solid;
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.nav-link:first-child{
  border-radius: 5px 0 0 5px;
}
.nav-link:last-child{
  border-radius: 0 5px 5px 0;
}
.w--current{
  color: #000;
  background-color: #0dcaf0;
}
</style>
