<template>
  <section class="user-profile text-white p-5">
    <button
      class="btn btn-secondary btn-lg ps-5 align-self-start"
      @click="goBack() ? $router.go(-1) : $router.push('/')"
    >
      Back
    </button>
    <div class="user-profile-wrapper mt-5 pb-5 border-bottom">
      <img
        class="user-profile_avatar rounded-circle mt-3"
        :src="avatar"
        alt="avatar"
        width="200"
        height="200"
      >
      <div class="user-profile__info-wrapper d-flex flex-column">
        <span class="user-profile_username mt-3">{{ username }}</span>
        <span class="user-profile_name mt-3">{{ name }}</span>
        <span class="user-profile_info mt-3">{{ info }}</span>
        <div class="follow-wrap mt-3">
          <span>Followers
            {{
              this.$store.state.user_info[
                this.$store.state.user_info.length - 1
              ].followers || followers
            }}</span>
          <span>Following
            {{
              this.$store.state.user_info[
                this.$store.state.user_info.length - 1
              ].following || following
            }}</span>
        </div>
        <div class="user-profile_rating mt-3">
          <div
            v-for="(heart, index) in 5"
            :key="index"
            :class="{ 'user-profile_rating-heart': index <= userRating, 'user-profile_rating-heart-empty': index >= userRating }"
          />
          <div class="user-profile_rating-number">
            {{ userRating }}
          </div>
        </div>
      </div>
    </div>
    <p class="mt-3">
      Reports (total {{ totalReports }}):
    </p>
    <ul class="list-group">
      <li
        v-for="(report, index) in reportsList"
        :key="index"
        class="list-group-item list-group-item-danger mt-1 d-flex row"
      >
        <div class="col-sm">
          <img
            :src="getAvatarUrl(report.accountId)"
            alt="avatar"
            width="75"
            height="75"
            class="rounded-circle"
          >
        </div>
        <span class="col-sm">{{
          usersReportsArray.get(report.accountId).username
        }}</span>
        <span class="col">{{ report.reason }}</span>
        <span
          class="col-6"
        >Report created on: {{ getDate(report.created) }}</span>
      </li>
    </ul>
    <div class="user-profile__content-wrap d-flex flex-wrap mt-5">
      <div
        v-for="(post, index) in contentList"
        :key="index"
        class="user-profile__content-post"
      >
        <img
          class="user-profile__post-image"
          :src="getPostUrl(post)"
          alt="picture"
          width="100"
          height="100"
        >
        <span class="mt-3 ms-3">{{ post.name }}</span>
        <span
          class="mt-3 ms-3"
          :class="{ 'text-truncate': isTextExpanded !== post.id }"
          @click="expandText(post, index)"
        >{{ post.content }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import {
  USER_DEFAULT_AVATAR,
  USER_DEFAULT_NAME,
  USER_DEFAULT_USERNAME,
} from "../constants.js";
export default {
  name: "UserProfile",

  data() {
    return {
      id: this.$router.currentRoute.path,
      avatar: USER_DEFAULT_AVATAR,
      name: USER_DEFAULT_NAME,
      username: USER_DEFAULT_USERNAME,
      info: `Profile information...`,
      followers: 0,
      following: 0,
      isTextExpanded: false,
      totalReports: 0,
      usersReportsArray: [],
      userRating: 0,
    };
  },
  computed: {
    contentList() {
      return this.$store.state.all_posts;
    },
    reportsList() {
      return this.$store.state.reports_array.data;
    },
    ratingList() {
      if (!this.$store.state.rating.count) {
        return [];
      }
      return this.$store.state.rating.count;
    },
  },
  mounted() {
    let id = this.$router.currentRoute.path.split(`/`).pop();
    this.$store.dispatch(`GET_ALL_POSTS`, id);
    this.$store.dispatch(`GET_USER_INFO`, id).then(() => {
      this.avatar = this.getAvatarUrl(
        this.$store.state.user_info[this.$store.state.user_info.length - 1].id
      );
      this.name =
        this.$store.state.user_info[
          this.$store.state.user_info.length - 1
        ].name;
      this.username =
        this.$store.state.user_info[
          this.$store.state.user_info.length - 1
        ].username;
      this.info =
        this.$store.state.user_info[
          this.$store.state.user_info.length - 1
        ].info;
    });
    this.$store.dispatch(`GET_REPORTS_ARRAY`, `?targetId=${id}`).then(() => {
      this.totalReports = this.$store.state.reports_array.data.length;
      const ids = [];
      this.$store.state.reports_array.data.map((item) => {
        ids.push(item.accountId);
      });
      this.$store.dispatch(`GET_ALL_USERS_INFO`, ids).then(() => {
        this.usersReportsArray = this.$store.state.all_users_info;
      });
    });
    this.$store
      .dispatch(`GET_FOLLOWERS_FOLLOWINGS`, `?followee=${id}`)
      .then(() => {
        this.followers = this.$store.state.followers_following_array.total;
      })
      .then(() => {
        this.$store
          .dispatch(`GET_FOLLOWERS_FOLLOWINGS`, `?follower=${id}`)
          .then(() => {
            this.following = this.$store.state.followers_following_array.total;
          });
      });
    this.$store.dispatch(`GET_RATING`, `/${id}`).then(() => {
      this.userRating = this.$store.state.rating.count;
    });
  },
  methods: {
    goBack() {
      this.$store.state.all_posts = [];
      return window.history.length > 2;
    },
    // EXPAND TEXT

    expandText(post, i) {
      if (!this.isTextExpanded) {
        this.isTextExpanded = post.id;
      } else {
        this.isTextExpanded = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "src/styles/_variables.scss";

img {
  display: block;
  object-fit: cover;
}

.user-profile {
  font-size: $font-size-small;
  min-height: calc(100vh - 52px);
  @include media-breakpoint-up(md) {
    font-size: $font-size-medium;
  }
  @include media-breakpoint-up(lg) {
    font-size: $font-size-large;
  }
}
.user-profile_avatar {
  display: block;
  object-fit: cover;
  @include media-breakpoint-up(md) {
    width: 250px;
    height: 250px;
  }
  @include media-breakpoint-up(lg) {
    width: 280px;
    height: 280px;
  }
}
.user-profile-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}
.user-profile__info-wrapper {
  @include media-breakpoint-up(md) {
    width: 20em;
    margin-left: 2em;
  }
  @include media-breakpoint-up(md) {
    width: 25em;
    margin-left: 3em;
  }
}
.btn {
  background-image: url("../assets/img/previous.svg");
  background-repeat: no-repeat;
  background-position: 10% 30%;
  background-size: 1.5em 1.5em;
}
.follow-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.user-profile_rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 172px;
  height: 58px;
  background: $item-color;
  border-radius: 33.5px;
  padding: 1em;
}
.user-profile_rating-heart {
  width: 15px;
  height: 15px;
  background-image: url("../assets/img/icon-rating-heart.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 25px;
}
.user-profile_rating-heart-empty {
  width: 15px;
  height: 15px;
  background-image: url("../assets/img/icon-rating-heart-empty.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
}
.user-profile__content-wrap {
  gap: 1em;
  border: solid 1px transparent;
}
.user-profile__content-post {
  display: flex;
  flex-direction: column;
  width: 16.9em;
  min-height: 25em;
  background-color: $item-color;
  border: solid 1px transparent;
  @include media-breakpoint-up(md) {
    width: 19.1em;
  }
  @include media-breakpoint-up(lg) {
    width: 18.7em;
  }
  @include media-breakpoint-up(xl) {
    width: 31.7%;
  }
  @include media-breakpoint-up(xxl) {
    width: 32%;
  }
}
.user-profile__post-image {
  width: 100%;
  height: 16.7em;
  @include media-breakpoint-up(md) {
  }
}
</style>
